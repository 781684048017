<template>
  <div>
    <v-expansion-panels
      v-model="panel"
      dark
      accordion
      multiple
    >
      <v-expansion-panel :key="0">
        <v-expansion-panel-header :hide-actions="false">
          {{ $t('grid_panel.general_information') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            v-if="network"
            style="margin-top: 0px"
          >
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('name') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ network }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('voltage') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.voltageCount }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.num_stations') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.stationsCount }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.num_transformers') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.transformersCount }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.num_connection_points') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.cgpsCount }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.num_clients') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.clientsCount }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row style="margin-bottom: 0px">
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.total_length_conductor') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.lengthLines }} m
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  name: 'NetworkDetails',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    network: {
      type: String,
      default: ''
    },
    stationInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      panel: [0]
    }
  },
  computed: {
    lines () {
      const lines = []
      this.stationInfo.lines.forEach((line) => {
        lines.push(line.ID)
      })
      return lines
    }
  }
}
</script>
<style scoped>
.v-expansion-panel-content {
  font-size: 14px;
}
.col-12 {
  padding: 1px 15px !important;
  text-align: left;
}
.hr-separator-dark{
  margin: 12px 0;
  border-top: 0.5px solid #444;
  border-bottom: none;
  border-left: none;
  border-right:none;
}
</style>
