<template>
  <div>
    <v-expansion-panels
      v-model="panel"
      dark
      accordion
      multiple
    >
      <v-expansion-panel :key="0">
        <v-expansion-panel-header :hide-actions="false">
          {{ $t('grid_panel.general_information') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row style="margin-top:0px">
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('name') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.name }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('description') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.description || $t('grid_panel.notprovided') }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.max_current') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.amps }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row style="margin-bottom:0px">
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid.network') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ network }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="hasMeters"
        :key="1"
      >
        <v-expansion-panel-header :hide-actions="false">
          {{ $t('grid_panel.meters_information') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row style="margin-top:0px">
            <v-col cols="2">
              ID
            </v-col>
            <v-col cols="3">
              {{ $t('name') }}
            </v-col>
            <v-col cols="3">
              {{ $t('phase') }}
            </v-col>
            <v-col cols="3">
              {{ $t('meters_information') }}
            </v-col>
          </v-row>
          <v-row
            v-for="meter in info.supplyPointsData"
            :key="meter.ID"
          >
            <v-col
              cols="2"
              style="padding:4px"
            >
              {{ meter.id }}
            </v-col>
            <v-col
              cols="3"
              style="padding:4px"
            >
              {{ meter.name }}
            </v-col>
            <v-col
              cols="3"
              style="padding:4px"
            >
              {{ meter.phase }}
            </v-col>
            <v-col
              cols="3"
              style="padding:4px"
            >
              {{ meter.meter_name }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>

export default {
  name: 'ConnectionPointDetails',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    network: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      panel: [0]
    }
  },
  computed: {
    hasMeters () {
      return this.info.supplyPointsData.length > 0
    }
  },
  mounted() {
    if (this.info.supplyPointsData) {
    this.info.supplyPointsData = this.info.supplyPointsData.map(meter => ({
      id: meter.id || 'N/A',
      name: meter.name || 'N/A',
      phase: meter.phase || 'N/A',
      meter_name: meter.meter_name || 'N/A'
    }));
    }
  }
}
</script>
<style scoped>
.v-expansion-panel-content{
    font-size:14px
}
.col-12{
    padding:1px 15px !important;
    text-align:left;
}
.hr-separator-dark{
  margin: 12px 0;
  border-top: 0.5px solid #444;
  border-bottom: none;
  border-left: none;
  border-right:none;
}
</style>
