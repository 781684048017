var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('l-icon',{attrs:{"icon-anchor":_vm.staticAnchor,"class-name":_vm.getIcon}},[_c('div',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.emptyText)+" ")])]),_c('l-tooltip',{attrs:{"content":'<div style='+_vm.classDiv+'><br>' +
      '<p><b> Station: ' + _vm.name + '</b></p><br>' +
      '<p><b>Power (kW):</b></p>' +
      '<p>Max: ' + _vm.powerValues[0] + '</p>' +
      '<p>Min: ' + _vm.powerValues[1] + '</p> <br>' +
      '<p><b><span style='+_vm.statusColor+'>Real Time</span></b></p>' +
      '<p>Actual Power (kW): <span style='+_vm.statusColor+'>' + _vm.powerValues[2] + '</span></p><br>' +
      '<p>First measurement: <span style='+_vm.statusColor+'>' + (_vm.currentEarliestMeasurement ? new Date(_vm.currentEarliestMeasurement).toLocaleString() : null) + '</span></p>' +
      '<p>Last measurement: <span style='+_vm.statusColor+'>' + (_vm.currentLatestMeasurement ? new Date(_vm.currentLatestMeasurement).toLocaleString() : null) + '</span></p><br>' +
      '<p><b><span style='+_vm.statusColor+'>' + _vm.statusName + '</span></b></p><br>' +
      '<p><b><span style='+_vm.statusColorForecast+'>Expected for tomorrow</span></b></p>' +
      '<p><b><span style='+_vm.statusColorForecast+'>' + _vm.statusNameForecast + '</span></b></p>' +
      '<p>Forecasted peak Power (kW):<b><span style='+_vm.statusColorForecast+'>' + _vm.forecastedPeakTotalPower + '</span></b></p>' +
      '<p>Time forecasted peak:<b><span style='+_vm.statusColorForecast+'>' + (_vm.forecastedPeakMeasurementTime ? new Date(_vm.forecastedPeakMeasurementTime).toLocaleString() : null) + '</span></b></p>' +
      '</div>'}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }