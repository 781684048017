<template>
  <div>
    <LMap
      style="background-color: #272727;"
      id="map"
      ref="myMap"
      :center="center"
      :zoom="zoom"
      :max-zoom="maxZoom"
      :bounds="bounds"
      :padding="padding"
      :options="mapOptions"
      @update:zoom="zoomUpdate"
      @ready="mapReady()"
    >
      <NavBarMap
        :mode="modeSelected"
        :list-elements="listElements"
        :digital-twin-map="digitalTwinMap"
        :grid-inspection-map="gridInspectionMap"
        :types-view-g-i="typesViewGI"
        :selected-type-view-map="selectedTypeViewMap"
        :networks="networks"
        :show-hcm-warning="showHcmWarning"
        @changeMode="changeMode"
        @showContextMenu="allowContextMenu = $event"
        @toggleLoading="toggleLoading"
        @selectElement="selectElement"
        @toggleNavbar="toggleNavbar"
        @updateTypeViewMap="updateTypeViewMap"
      />
      <div
        v-if="showAlert"
        class="d-flex justify-center align-center"
      >
        <v-alert
          dense
          border="left"
          :type="showAlert"
          class="grid-alert"
          dismissible
        >
          {{ alertText }}
        </v-alert>
      </div>
      <LControlLayers
        v-if="showControlLayers"
        position="bottomright"
      />
      <l-control-zoom position="bottomleft" />
      <LTileLayer
        v-for="optionLayer in optionsLayer"
        :key="optionLayer.name"
        :name="optionLayer.name"
        :visible="optionLayer.visible"
        :options="layerOptions"
        :opacity="optionLayer.opacity"
        :tile-layer-class="tileLayerClass"
        :url="optionLayer.url"
        :attribution="optionLayer.attribution"
        layer-type="base"
      />
      <l-layer-group
        v-for="layer in networks"
        :key="layer.level"
        ref="layer"
        layer-type="overlay"
        :name="layer.level"
        :visible="visibleLow"
      >
        <div v-if="networks">
          <StationLayer
            v-for="station in layer.stations"
            :key="station.NAME"
            :station-data="station"
            :color-station="station.color"
            :mode="mode"
            :zoom="zoom"
            :multiselect="multiselect"
            :context-menu="enableContextMenu"
            :level="layer.level"
            @hover="hover"
            @leave="leave"
            @click="click"
          />
          <FullNetwork
            v-for="network in layer.networks"
            :key="network.network.NAME"
            :network-data="network"
            :mode="modeSelected"
            :digital-twin-map="digitalTwinMap"
            :hosting-capacity-map="hostingCapacityMap"
            :connection-point-context-menu="enableContextMenu"
            :connection-point-h-c-m-context-menu="enableHCMContextMenu"
            :hc-phase-index="hcPhaseIndex"
            :fuse-context-menu="enableContextMenu"
            :station-context-menu="enableContextMenu"
            :line-context-menu="enableContextMenu"
            :multiselect="multiselect"
            :level="layer.level"
            :zoom="zoom"
            :hc-zoom="zoom"
            :selected-type-view-map="selectedTypeViewMap"
            @hover="hover"
            @leave="leave"
            @click="click"
            @onHCReservationClick="onHCReservationClick"
          />
          <div v-if="layer.switchBoxes">
            <SwitchBox
              :switch-boxes-data="layer.switchBoxes"
              :mode="modeSelected"
              color-station="#fff"
              :digital-twin-map="digitalTwinMap"
              :hosting-capacity-map="hostingCapacityMap"
              :context-menu="enableContextMenu"
              @click="click"
              @hover="hover"
              @leave="leave"
            />
          </div>
          <div v-if="layer.openFuses">
            <FuseLayer
              :fuses-data="layer.openFuses"
              :mode="modeSelected"
              :color="colorOpenFuses"
              :context-menu="enableContextMenu"
              :hosting-capacity-map="hostingCapacityMap"
              @click="click"
            />
          </div>
          <div v-if="layer.isolatedLines">
            <LineLayer
              :isolated="true"
              :lines-data="layer.isolatedLines"
              :context-menu="enableContextMenu"
              mode="line_mode"
              color-station="#fff"
              @click="click"
            />
          </div>
          <!--The menu that appears when you make a right click in some element of the map-->
          <ContextMenu :menu="contextMenu" />
          <MarkerLayer />
        </div>
      </l-layer-group>
    </LMap>

    <CMLoader
      v-if="loadingSelf.isVisible"
      :loading="loadingSelf.isVisible"
      :message="loadingSelf.message"
    />
    <CMError
      v-if="violations.dialog"
      title="Warning!"
      :text="`${violations.data.length} power quality violations were encountered`"
      @hideError="toggleViolationsDialog"
    />
    <MapInformation
      :coordinates-text="coordinatesLat + ', ' + coordinatesLng"
      :mode="modeSelected"
    />
  </div>
</template>

<script>
import proj4 from 'proj4'
import {
  LMap,
  LTileLayer,
  LControlLayers,
  LLayerGroup,
  LControlZoom
} from 'vue2-leaflet'
import { mapState } from 'vuex'
import L from 'leaflet'
import 'mapbox-gl'
import 'mapbox-gl-leaflet'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'leaflet-area-select'
import * as turf from '@turf/turf'
import 'leaflet/dist/leaflet.css'

import clickElements from '@/mixins/map/mapElementSelectMixin'
import unclickElements from '@/mixins/map/mapElementUnselectMixin'
import dtChangesMixin from '@/mixins/dtChangesMixin'
import dtNewElementMixin from '@/mixins/dtNewElementMixin'
import vuexMixin from '@/mixins/vuexMixin'
import FullNetwork from '@/components/Map/NetworkGroupedLayer.vue'
import SwitchBox from '@/components/Map/SwitchBoxLayer.vue'
import FuseLayer from '@/components/Map/FuseLayer.vue'
import StationLayer from '@/components/Map/StationLayer.vue'
import CMLoader from '@/components/Common/CMLoader.vue'
import ContextMenu from '@/components/Map/ContextMenu.vue'
import NewConnectionsPVForm from '@/components/DigitalTwin/Forms/DTNewConnectionsPVForm.vue'
import NewConnectionsHPForm from '@/components/DigitalTwin/Forms/DTNewConnectionsHPForm.vue'
import NewConnectionsEVForm from '@/components/DigitalTwin/Forms/DTNewConnectionsEVForm.vue'
import NewCustomProfileForm from '@/components/DigitalTwin/Forms/DTNewCustomProfileForm.vue'
import DTEditChangesForm from '@/components/DigitalTwin/Forms/DTEditChangesForm.vue'
import DTNewConnectionPointForm from '@/components/DigitalTwin/Forms/DTNewConnectionPointForm.vue'
import DTCableChangeForm from '@/components/DigitalTwin/Forms/DTCableChangeForm.vue'
import DTDisableElementForm from '@/components/DigitalTwin/Forms/DTDisableElementForm.vue'
import SmartMetersForm from '@/components/DigitalTwin/Forms/SmartMetersForm.vue'
import HCMFullReservationForm from '@/components/GridInspection/HostingCapacityMap/HCMFullReservationForm.vue'
import MarkerLayer from '@/components/Map/MapMarkerLayer.vue'
import MapInformation from '@/components/Map/MapInformation.vue'
import NavBarMap from '@/components/Map/NavBarMap.vue'
import LineLayer from '@/components/Map/LineLayer.vue'
import CMError from '@/components/Common/CMError.vue'
import CMDialog from '@/components/Common/CMDialog.vue'
import NewConnectionsESSForm from '@/components/DigitalTwin/Forms/DTNewConnectionsESSForm.vue' // Battery and Battery Control Integration - ESS Form

export default {
  name: 'MapBase',
  components: {
    LMap,
    LTileLayer,
    LControlLayers,
    LLayerGroup,
    FullNetwork,
    SwitchBox,
    LControlZoom,
    FuseLayer,
    StationLayer,
    ContextMenu,
    CMLoader,
    MarkerLayer,
    MapInformation,
    NavBarMap,
    LineLayer,
    CMError
  },
  mixins: [clickElements, unclickElements, dtChangesMixin, vuexMixin, dtNewElementMixin],
  props: {
    mode: {
      type: String,
      default: ''
    },
    enabledContextMenu: {
      type: Boolean,
      default: false
    },
    networks: {
      type: Array,
      default: () => ([])
    },
    isolatedLines: {
      type: Array,
      default: () => ([])
    },
    stationOpenFuses: {
      type: Array,
      default: () => ([])
    },
    listElements: {
      type: Object,
      default: () => ({})
    },
    digitalTwinMap: {
      type: Boolean,
      default: false
    },
    hostingCapacityMap: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: 'Fetching data'
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    gridBounds: {
      type: Object,
      default: () => ({})
    },
    areaSelector: {
      type: Boolean,
      default: true
    },
    showControlLayers: {
      type: Boolean,
      default: true
    },
    boundsUpdated: {
      type: [Array, Object],
      default: () => ([])
    },
    isSelectAreaEnable: {
      type: Boolean,
      default: false
    },
    showMediumVoltage: {
      type: Boolean,
      default: true
    },
    gridInspectionMap: {
      type: Boolean,
      default: false
    },
    typesViewGI: {
      type: Array,
      default: () => ([])
    },
    selectedTypeViewMap: {
      type: [Array, Number, String],
      default: () => ([])
    },
    hcPhaseIndex: {
      type: Number,
      default: null
    },
    showHcmWarning: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hostMap: this.$API_MAPSERVERHOST,
      portMap: this.$API_MAPSERVERPORT,
      zoom: 9,
      maxZoom: 22,
      center: [43.39228, -5.66335],
      padding: [100, 100],
      mapOptions: {
        zoomControl: false,
        attributionControl: false
      },
      tileLayerClass: (url, options) => L.mapboxGL(options),
      layerOptions: {
        style:
          this.$MAP_SERVER_URL
      },
      optionsLayer: [
        {
          name: 'Street Map',
          attribution: 'Street Map',
          url: 'Not available yet',
          opacity: 0
        }
      ],
      map: null,
      colorMemo: null,
      stationColorMemo: null,
      colorOpenFuses: '#00d400',
      modeSelected: null,
      allowContextMenu: false,
      loadingSelf: {
        isVisible: false,
        message: 'Fetching data'
      },
      bounds: undefined,
      contextMenuCurrent: undefined,
      visibleLow: true,
      visibleMedium: true,
      isSelectAreaEnableSelected: false,
      rectangleLayer: null,
      coordinatesLat: '',
      coordinatesLng: ''
    }
  },
  computed: {
    ...mapState({
      markers: ({ markers }) => markers,
      isContextMenu: ({ contextMenu }) => contextMenu.isVisible,
      element: ({ element }) => element,
      contextMenuType: ({ contextMenuElement }) => contextMenuElement.type,
      contextMenuElement: ({ contextMenuElement }) => contextMenuElement.info,
      DTChanges: ({ DTChanges }) => DTChanges,
      selectedStations: (state) => state.projectNetworks,
      connectionPoint: ({ connectionPoint }) => connectionPoint,
      violations: ({ violations }) => violations
    }),

    gridCenter () {
      // Initializing proj4 parameters
      const corner1Location = proj4(
        this.$sessionStorage.projectCoordSystem,
        this.$WGS84,
        [this.gridBounds.Xmin, this.gridBounds.Ymin]
      ) || []
      const corner2Location = proj4(
        this.$sessionStorage.projectCoordSystem,
        this.$WGS84,
        [this.gridBounds.Xmax, this.gridBounds.Ymax]
      ) || []
      const corner1 = L.latLng(corner1Location[1], corner1Location[0])
      const corner2 = L.latLng(corner2Location[1], corner2Location[0])
      return L.latLngBounds(corner1, corner2)
    },

    showAlert () {
      let alertMessage = false
      if (this.gridInspectionMap && !this.networks.length) {
        alertMessage = 'error'
      } else {
        const lowNetworksIndex = this.networks.findIndex(
          (networkElement) => networkElement.level > 1000 && networkElement.level > 0
        )
        if (this.gridInspectionMap && lowNetworksIndex === -1) {
          alertMessage = 'warning'
        }
      }
      return alertMessage
    },

    alertText () {
      if (this.showAlert === 'warning') {
        return this.$t('hcm.not_available_hcm')
      }
      return this.$t('grid.empty_grid')
    },

    enableContextMenu () {
      return this.enabledContextMenu && this.allowContextMenu
    },
    enableHCMContextMenu () {
      return this.enabledContextMenu && this.hostingCapacityMap
    },
    cgpHasChanges () {
      return (
        this.markers?.some((m) => m.element?.ID === this.connectionPoint.ID)
      )
    },
    lineFull () {
      const lineOccupiedConnectionPoints = this.getLineOccupiedConnectionPoints()
      return (lineOccupiedConnectionPoints.length >= 2)
    },

    PhaseLength () {
      return this.DTChanges.Phase.CGP.length
    },

    fuseHasChanges () {
      return (
        this.markers?.some((m) => m.element.ID === this.contextMenuElement.ID)
      )
    },

    stationHasChanges () {
      return (
        this.markers?.some((m) => m.element.ID === this.connectionPoint.ID)
      )
    },

    contextMenu () {
      if (this.contextMenuType === 'contextMenuFuse') {
        return this.contextMenuFuse
      } else if (this.contextMenuType === 'contextMenuStation') {
        return this.contextMenuStation
      } else if (this.contextMenuType === 'contextMenuLine') {
        return this.contextMenuLine
      } else if (this.hostingCapacityMap) {
        return this.contextMenuConnForHCM
      }
      return this.contextMenuConn
    },

    contextMenuStation () {
      return [
        /*
        // Batteries are not ready in this moment
        {
          title: this.$t('new_connections.add_ess'),
          isVisible: !this.stationHasChanges,
          fn: {
            type: 'toggleDialog',
            component: NewConnectionsESSForm
          }
        },
        {
          title: 'Reset Position',
          isVisible: this.stationHasChanges,
          fn: {
            type: 'removeBattery'
          }
        },
        */
        {
          title: 'Toggle element',
          fn: {
            type: 'toggleDialog',
            component: CMDialog,
            payload: {
              children: DTDisableElementForm
            }
          }
        }
      ]
    },

    contextMenuFuse () {
      return [
        {
          title: 'Switch',
          isVisible: !this.fuseHasChanges,
          fn: {
            type: 'checkConnectivityIssues'
          }
        },
        {
          title: 'Reset Position',
          isVisible: this.fuseHasChanges,
          fn: {
            type: 'removeFuse'
          }
        }
      ]
    },

    contextMenuConn () {
      return [
        {
          title: this.$t('new_connections.add_element'),
          children: [
            {
              title: this.$t('new_connections.add_pv'),
              fn: { type: 'toggleDialog', component: NewConnectionsPVForm }
            },
            {
              title: this.$t('new_connections.add_ev'),
              fn: { type: 'toggleDialog', component: NewConnectionsEVForm }
            },
            {
              title: this.$t('new_connections.add_hp'),
              fn: { type: 'toggleDialog', component: NewConnectionsHPForm }
            },
            {
              title: this.$t('new_connections.add_ess'),
              fn: { type: 'toggleDialog', component: NewConnectionsESSForm }
            },
            {
              title: 'Add custom profile',
              fn: { type: 'toggleDialog', component: NewCustomProfileForm }
            }
          ]
        },
        {
          title: this.$t('new_connections.edit_element'),
          isVisible: this.cgpHasChanges,
          fn: {
            type: 'toggleDialog',
            component: DTEditChangesForm,
            payload: {
              forms: {
                PV: NewConnectionsPVForm,
                EV: NewConnectionsEVForm,
                HP: NewConnectionsHPForm,
                ESS: NewConnectionsESSForm,
                Phase: SmartMetersForm,
                CP: NewCustomProfileForm,
                CGP_New: DTNewConnectionPointForm
              }
            }
          }
        },
        {
          title: this.$t('new_connections.remove_client'),
          fn: {
            type: 'toggleDialog',
            component: SmartMetersForm,
            payload: { actionType: 'Remove Client' }
          }
        },
        {
          title: this.$t('new_connections.change_phase'),
          fn: {
            type: 'toggleDialog',
            component: SmartMetersForm,
            payload: { actionType: 'Change Phase' }
          }
        },
        {
          title: 'Toggle element',
          fn: {
            type: 'toggleDialog',
            component: CMDialog,
            payload: {
              children: DTDisableElementForm
            }
          }
        }
      ]
    },
    contextMenuConnForHCM () {
      return [
        {
          title: this.$t('hcm.full_reserve') + ` (${this.selectedTypeViewMap === 'consumption' ? this.$t('consumption') : this.$t('generation')})`,
          fn: {
            type: 'toggleDialog',
            component: CMDialog,
            // The Type of Capacity can be 0 - full capacity or 1 - flexible capacity
            payload: {
              phaseIndex: this.hcPhaseIndex,
              children: HCMFullReservationForm,
              capacityType: 0,
              reservationType: this.selectedTypeViewMap === 'generation' ? 0 : 1,
              networks: this.networks,
              updateMapAfterHCReservation: (networkName) => this.updateMapAfterHCReservation(networkName)
            }
          }
        }
      ]
    },
    contextMenuLine () {
      return [
        {
          title: 'Add new connection point',
          isVisible: !this.lineFull,
          fn: {
            type: 'toggleDialog',
            component: DTNewConnectionPointForm
          }
        },
        {
          title: 'Toggle element',
          fn: {
            type: 'toggleDialog',
            component: CMDialog,
            payload: {
              children: DTDisableElementForm
            }
          }
        },
        {
          title: this.$t('new_connections.edit_cable'),
          fn: {
            type: 'toggleDialog',
            component: DTCableChangeForm
          }
        },
      ]
    }
  },

  watch: {
    loadingText (val){
      this.loadingSelf.message = val
    },

    mode (val) {
      this.modeSelected = val
    },

    loading () {
      this.toggleLoading(this.loadingText)
    },

    isSelectAreaEnable (val) {
      this.isSelectAreaEnableSelected = val
      if (this.modeSelected === 'network_mode') {
        if (val) {
          this.drawRectangle()
        } else if (this.rectangleLayer != null) {
          this.map.removeLayer(this.rectangleLayer)
        }
      }
    },

    boundsUpdated (val) {
      this.bounds = val.length ? val : this.gridCenter
    }
  },

  mounted () {
    this.modeSelected = this.mode
    this.bounds = this.gridCenter
    this.isSelectAreaEnableSelected = this.isSelectAreaEnable
  },

  methods: {
    zoomUpdate (zoom) {
      this.zoom = zoom
    },
    mapReady () {
      let cLat
      let cLng
      this.map = this.$refs.myMap.mapObject
      this.map.on('mousemove', (e) => {
        cLat = e.latlng.lat.toFixed(6)
        cLng = e.latlng.lng.toFixed(6)
        this.coordinatesLat = cLat
        this.coordinatesLng = cLng
      })
    },

    changeMode (modeSelected) {
      this.modeSelected = modeSelected
      this.$emit('changeMode', modeSelected)
    },

    hover (level, network) {
      if (network && !this.hostingCapacityMap) {
        this.networks.forEach((voltageLevel) => {
          const elementHover = voltageLevel.networks.find((networkElements) => networkElements.network.NAME === network)
          if (elementHover) {
            this.colorMemo = elementHover.color
            elementHover.color = '#f4c020'
          }
          if (voltageLevel.level === level) {
            const stationHover = voltageLevel.stations.find((station) => station.NAME === network)
            if (stationHover) {
              this.stationColorMemo = stationHover.color
              stationHover.color = '#f4c020'
            }
          }
        })
      }
    },

    leave (level, network) {
      if (network && !this.hostingCapacityMap) {
        this.networks.forEach((voltageLevel) => {
          const elementHover = voltageLevel.networks.find((networkElements) => networkElements.network.NAME === network)
          if (elementHover) { elementHover.color = this.colorMemo }

          if (voltageLevel.level === level) {
            const stationHover = voltageLevel.stations.find((station) => station.NAME === network)
            if (stationHover) { stationHover.color = this.stationColorMemo }
          }
        })
      }
    },

    click (level, network, elementId, elementType, isSearchElement = false) {
      this.$store.dispatch('setClick', { lat: this.coordinatesLat, lng: this.coordinatesLng, idElement: elementId })
      this.$emit('click', level, network, elementId, elementType, isSearchElement)
      this.$store.dispatch('setElement', { path: 'typeElement', value: elementType })
      this.$store.dispatch('setElement', { path: 'idElementToShow', value: elementId.toString() })
      this.bounds = this.getBounds(level, network, elementId, elementType)
      if (this.isContextMenu) {
        this.$store.dispatch('toggleElement', 'contextMenu')
      }
    },

    toggleLoading (message) {
      this.loadingSelf.isVisible = !this.loadingSelf.isVisible
      message && (this.loadingSelf.message = message)
    },

    toggleNavbar () {
      this.$emit('toggleNavbar')
    },

    getBounds (level, network, elementId, elementType) {
      // whole information of the station
      this.padding = [160, 160]
      let voltageLevel
      let networkSelected
      if (network) {
        voltageLevel = this.networks.find((element) => element.level === level)
        networkSelected = voltageLevel.networks.find((x) => x.network.NAME === network)
      }
      if (this.modeSelected === 'element_mode') {
        if (elementType === 'line') {
          const lineSelected = networkSelected.lines.find(
            (line) => line.ID === elementId
          )
          return lineSelected.latLng
        }
        if (elementType === 'connectionPoint') {
          const connectionPointSelected =
            networkSelected.connection_points.find(
              (connectionPoint) => connectionPoint.ID === elementId
            )
          const center = L.latLng(
            connectionPointSelected.latLng[0],
            connectionPointSelected.latLng[1]
          )
          return center.toBounds(40)
        }
        if (elementType === 'station') {
          const singleStationSelected = voltageLevel.stations.find(
            (x) => x.ID === elementId
          )
          const center = L.latLng(
            singleStationSelected.latLng[0],
            singleStationSelected.latLng[1]
          )
          return center.toBounds(40)
        }
        if (networkSelected) {
          if (elementType === 'fuse') {
            let fuseSelected = networkSelected.closed_fuses.find(
              (fuse) => fuse.ID === elementId
            )
            if (fuseSelected === undefined) {
              fuseSelected = networkSelected.station_closed_fuses.find(
                (fuse) => fuse.ID === elementId
              )
              if (fuseSelected === undefined) {
                fuseSelected = networkSelected.station_open_fuses.find(
                  (fuse) => fuse.ID === elementId
                )
                if (fuseSelected === 'undefined') {
                  fuseSelected = this.openFuses.find(
                    (fuse) => fuse.ID === elementId
                  )
                }
              }
            }
            return fuseSelected.latLngs
          }
        } else {
          let sbFuses = this.networks.flatMap(networkLevels => 
            (networkLevels.switchBoxes || []).flatMap(switchBox => switchBox.fuses)
          )
          const fuseSelected = sbFuses.find((fuse) => fuse.ID === elementId)
          return fuseSelected.latLngs
        }
      } else if (elementType === 'fuse' && !network) {
        const closedFuses = []
        const openFuses = []
        this.networks.forEach(level => {
          level.networks.forEach(network => {
            closedFuses.push(network.closed_fuses)
          })
          openFuses.push(level.openFuses)
        })
        const fuses = [...closedFuses.flat(1) ?? [], ...(openFuses.flat(1) ?? [])]
        const fuseSelected = fuses.find((fuse) => fuse.ID === elementId)

        return fuseSelected.latLngs
      } else if (elementType === 'station'){
         const singleStationSelected = voltageLevel.stations.find(
            (x) => x.ID === elementId
          )
          const center = L.latLng(
            singleStationSelected.latLng[0],
            singleStationSelected.latLng[1]
          )
          return center.toBounds(50)
      } else {
        this.padding = [20, 20]
        const bounds = []
        networkSelected?.lines?.forEach((line) => {
          line.latLng.forEach((point) => {
            bounds.push(point)
          })
        })
        return bounds
      }
      return -1
    },

    selectElement (type, id, station, level) {
      if (type !== 'network' && this.mode === 'network_mode') {
        this.changeMode('element_mode')
      } else if (type === 'network' && this.mode === 'element_mode') {
        this.changeMode('network_mode')
      }
      this.click(level, station, id, type, true)
    },
    /*
    showContextMenu (allowed) {
      this.allowContextMenu = allowed
    },
    */
    setComponentForm () {
      if (!this.cgpHasChanges) return false

      const devices = {
        PV: NewConnectionsPVForm,
        EV: NewConnectionsEVForm,
        HP: NewConnectionsHPForm,
        ESS: NewConnectionsESSForm
      }

      const currentMarker = this.markers.find(
        (m) => m.connectionPoint.ID === this.connectionPoint.ID
      )

      return devices[currentMarker.type]
    },
    drawRectangle () {
      if (!this.map.selectArea) return
      this.map.selectArea.enable()

      this.map.on('areaselected', (e) => {
        if (this.modeSelected === 'network_mode') {
          if (this.rectangleLayer != null && this.isSelectAreaEnableSelected) {
            this.drawSelectedLines()
            this.selectedStations.forEach((station) => {
              this.click(station.id, station.id, 'station')
            })
            this.map.removeLayer(this.rectangleLayer)
          }
          this.rectangleLayer = L.rectangle(e.bounds, {
            color: 'yellow',
            weight: 1
          }).addTo(this.map)
          this.drawSelectedLines()
        }
      })
    },

    drawSelectedLines () {
      const rectTurf = turf.polygon([[
        [this.rectangleLayer._latlngs[0][0].lng, this.rectangleLayer._latlngs[0][0].lat],
        [this.rectangleLayer._latlngs[0][1].lng, this.rectangleLayer._latlngs[0][1].lat],
        [this.rectangleLayer._latlngs[0][2].lng, this.rectangleLayer._latlngs[0][2].lat],
        [this.rectangleLayer._latlngs[0][3].lng, this.rectangleLayer._latlngs[0][3].lat],
        [this.rectangleLayer._latlngs[0][0].lng, this.rectangleLayer._latlngs[0][0].lat]
      ]])
      const connectionsPoints = []
      this.networks.forEach((level) => {
        level.networks.forEach((network) => {
          network.connection_points.forEach((cp) => {
            const location = proj4(this.projectCoordSystem, this.$WGS84, [cp.X, cp.Y, cp.NETWORK])
            connectionsPoints.push([location[0], location[1], location[2]])
          })
        })
      })
      const cpTurfPoints = turf.points(connectionsPoints)
      const ptsWithin = turf.pointsWithinPolygon(cpTurfPoints, rectTurf)
      const cgpsWithin = []
      ptsWithin.features.forEach((element) => {
        cgpsWithin.push(
          connectionsPoints.find(
            (ct) => ct[0] === element.geometry.coordinates[0] && ct[1] === element.geometry.coordinates[1]
          )
        )
      })
      let networksWithin = []
      cgpsWithin.forEach((cgpIn) => {
        networksWithin.push(cgpIn[2])
      })

      networksWithin = new Set(networksWithin)
      networksWithin.forEach((network) => {
        this.click('400', network, network, 'network')
      })
    },

    updateTypeViewMap (value) {
      this.$emit('updateTypeViewMap', value)
    },
    onHCReservationClick (network) {
      this.$emit('onHCReservationClick', network)
    },
    updateMapAfterHCReservation (networkName) {
      this.$emit('updateMapAfterHCReservation', networkName)
    },

    toggleViolationsDialog () {
      this.setVuexElement({
        path: 'violations',
        value: {
          ...this.violations,
          dialog: !this.violations.dialog
        }
      })
    }
  }
}
</script>

<style>
#map {
  height: 93vh;
}
.leaflet-top {
  z-index: 500;
}
.v-overlay__content {
  z-index: 502;
}
.leaflet-bottom {
  bottom: 40px;
  z-index: 500;
}
.nav-bar-map {
  height: 200px;
}
.grid-alert {
  width: 50%;
  z-index: 500;
  top:70px;
}
.leaflet-bottom {
  text-align: left;
  font-family: 'Faktum Regular', sans-serif !important;
}
</style>
