export default {
  appname: 'Nombre de la aplicación',
  lang: 'Idioma',
  ok: 'Ok',
  add: 'Añadir',
  cancel: 'Cancelar',
  close: 'Cerrar',
  remove: 'Eliminar',
  delete: 'Borrar',
  delete_all: 'Borrar todo',
  open: 'Abrir',
  new: 'Nuevo',
  edit: 'Editar',
  reset: 'Restablecer',
  create: 'Crear',
  confirm: 'Confirmar',
  name: 'Nombre',
  measurements: 'Medidas',
  events: 'Eventos',
  select_all: 'Seleccionar todas',
  select_by_id: 'Seleccionar por id o nombre',
  email: 'Email',
  grid_inspector_title: 'Inspector de red',
  digital_twin: 'Gemelo digital',
  grid_monitor_title: 'Monitor de red',
  load_analysis_title: 'Análisis de consumo',
  tia_map_title: 'TIA mapa',
  fetching: 'Obteniendo datos',
  plots: 'Gráficos',
  plot_one: 'Gráfico',
  info: 'Info',
  phase: 'Fase',
  unit: 'Unidades',
  power: 'Potencia',
  description: 'Descripción',
  optional: 'opcional',
  no_data_available: 'Sin datos disponibles',
  consumption: 'Consumo',
  generation: 'Generación',
  start_date: 'Fecha inicio',
  end_date: 'Fecha fin',
  creation_date: 'Fecha de creación',
  expiration_date: 'Fecha de expiración',
  installation_date: 'Fecha de instalación',
  mode: 'Modo',
  and: 'y',
  type: 'Tipo',
  click_to_change: 'Click para cambiar',
  voltage: 'Tensión',
  current: 'Corriente',
  apparent_power: 'Potencia aparente',
  active_power: 'Potencia Activa',
  reactive_power: 'Potencia Reactiva',
  losses: 'Pérdidas',
  heatmap: 'Mapa de calor',
  cable: 'Cable',
  length: 'Longitud',
  sync_map: 'Sincronizar mapa',
  formula: 'Fórmula',
  hours: 'Horas',
  not_available: 'No disponible',
  no_info: 'Sin información disponible',
  seconds: 'segundos',
  second_abb: 's',
  file: 'Archivo',
  file_title: 'Nombre del archivo',
  download: 'Descargar',
  select_data_download: 'Seleccione datos a descargar',
  download_csv: 'Descargar como csv',
  title_csv_to_download: 'Nombre del archivo csv para descargar',

  validations: {
    item_required: 'Campo obligatorio',
    equal_or_greater: 'El valor debe ser igual o superior a',
    item_between: 'El valor debe estar entre',
    integer: 'El valor debe ser un entero',
    number: 'El valor debe ser un número',
    not_special_character: 'Caracteres especiales no permitidos',
    no_white_space: 'No están permitidos los espacios',
    project_name_exists: 'Ya existe un proyecto con este nombre'
  },

  login: {
    password: 'Contraseña',
    login: 'Iniciar sesión',
    error_message:
      'Ha introducido una dirección de correo electrónico o una contraseña incorrectas.'
  },

  grid_set: {
    title: 'Establece la red a visualizar',
    by_area: 'Por zona',
    by_element: 'Por elemento',
    set_area: 'Establecer red',
    completed_fields: 'Campos completados',
    area_level: 'nivel',
    error:
      'Se ha producido un error al acceder a los datos. Por favor, inténtelo de nuevo.',
    type_element: 'Tipo de elemento*',
    element_id: 'Id de elemento*',
    element_name: 'Nombre del elemento*',
    radius: 'Radio',
    getting_grid_msg: 'Obteniendo datos para la red seleccionada'
  },

  grid: {
    network: 'Subred',
    networks: 'Subredes',
    meter: 'Medidor',
    meters: 'Medidores',
    station: 'Estación',
    stations: 'Estaciones',
    line: 'Linea',
    lines: 'Lineas',
    feeder: 'Alimentador',
    feeders: 'Alimentadores',
    connection_point: 'Acometida',
    connection_points: 'Acometidas',
    transformer: 'Transformador',
    transformers: 'Transformadores',
    supervisors: 'Supervisores',
    advanced_supervisors: 'Supervisores Avanzados',
    supply_points: 'Puntos de Suministro',
    smart_meters: 'Medidores inteligentes',
    empty_grid: 'No hay datos de cuadrícula disponibles para mostrar.'
  },

  nav_bar: {
    title: 'Inicio',
    configure: 'Configure su red',
    change_password: 'Cambiar contraseña',
    logout: 'Cierre de sesión',
    load_data: 'Importar datos',
    set_grid: 'Establecer red',
    admin_panel: 'Panel de administrador'
  },

  admin: {
    confirm_title: 'Confirmar',
    confirm_message: 'El usuario será eliminado',
    user_deleted: 'El usuario ha sido eliminado',
    reset_password: 'Restablecer contraseña',
    delete_user: 'Borrar usuario',
    user_selected: 'Usuario seleccionado:',
    indication: 'indica un campo obligatorio',
    department: 'Departamento',
    username: 'Nombre de usuario',
    email_hint: 'Será su correo electrónico de acceso',
    last_name: 'Apellido',
    last_name_hint: 'Su apellido',
    first_name: 'Nombre',
    first_name_rule: 'El nombre es obligatorio',
    add_user: 'Añadir usuario',
    company: 'Compañía',
    last_login: 'Última conexión',
    reset_password_message: 'Restablecer contraseña. La envía al usuario',
    add_user_message:
      'Usuario creado con esta contraseña. Cópiela y entre con su email y esta contraseña',
    rows_per_page: 'Filas por página'
  },

  grid_surveyor: {
    loading_grid_surveyor_msg: 'Cargando panel de control',
    lines: 'Líneas',
    connection_points: 'Acometidas',
    fuses: 'Fusibles',
    clients: 'Clientes'
  },
  substations: {
    station_count: 'Número de Estaciones',
    transformer_count: 'Número de transformadores',
    station_count_per_power: 'Número de estaciones por potencia (kVA)',
    station_count_per_feeder_count:
      'Número de estaciones por número de alimentadores',
    transformer_count_per_feeder_count:
      'Número de transformadores por número de alimentadores',
    transformer_count_per_power: 'Número de transformadores por potencia (kVA)',
    installed_stations_per_year: 'Estaciones instaladas por año',
    connection_per_station: 'Conexiones por estación',
    clients_per_station: 'Clientes por estación',
    km_per_station: 'Km de línea por estación'
  },
  lines: {
    km_of_line: 'Km de línea',
    km_per_station: 'Km por estación',
    m_per_connection: 'm por conexión',
    m_per_client: 'm por cliente',
    peak_current: 'Corriente de pico',
    underground: 'Bajo tierra',
    overhead: 'Sobrecarga'
  },
  connection: {
    connection_points: 'N. Acometidas',
    avg_connection_points_per_station: 'Promedio de acometidas por estación',
    avg_meters_per_connection_points:
      'Promedio de contadores por puntos de conexión'
  },
  fuses: {
    fuses_count: 'N. Fusibles',
    feeders_count: 'N. Alimentadores',
    maneuvers_count: 'N. Maniobras'
  },
  clients: {
    clients_count: 'N. Clientes'
  },

  map_nav_bar: {
    hide_bar: 'Ocultar barra de mapa',
    show_bar: 'Mostrar barra de mapa',
    network_mode: 'Modo subred',
    element_mode: 'Modo elemento',
    change_to_network_mode: 'Cambiar a modo subred',
    change_to_element_mode: 'Cambiar a modo elemento'
  },
  map: {
    sld: 'Diagrama unifilar',
    map_view: 'Mapa'
  },
  map_info: {
    area: 'Zona',
    volt_level: 'Nivel Volt.',
    low: 'Baja',
    coordinates: 'Coordenadas',
    element_hovered: 'Sobre elemento'
  },

  grid_inspector: {
    loading_msg: 'Cargando Inspector de Red',
    raw: 'Vista simple',
    connectivity: 'Vista de conectividad',
    hosting_capacity: 'Vista hosting capacity',
    hcm_generation: 'Generación',
    hcm_consumption: 'Consumo'
  },
  grid_panel: {
    general_information: 'Información general',
    meters_information: 'Información de medidores',
    transformer_information: 'Información de transformador',
    code: 'Código',
    max_current: 'Corriente Max.',
    num_stations: 'N.Estaciones',
    num_transformers: 'N.Transformadores',
    num_connection_points: 'N.Acometidas',
    num_clients: 'N.Clientes',
    total_length_conductor: 'Longitud total de conductor',
    tap_max: 'Toma max.',
    tap_min: 'Toma min.',
    tap_positions: 'Posiciones de toma',
    voltage_regulator: 'Regulador de tensión',
    num_station_sup: 'N.Supervisores de estación',
    num_line_sup: 'N.Supervisores de línea',
    num_lines: 'N.Líneas',
    num_active_lines: 'N.Líneas activas',
    notprovided: 'No especificado'
  },

  hcm: {
    loading_msg: 'Cargando mapa de capacidades',
    consumption: 'Consumo',
    not_available: 'No disponible',
    reserves: 'Reservas',
    reserve_kw: 'Reserva (kW)',
    consumption_hc: 'Capacidad de consumo',
    generation_hc: 'Capacidad de generación',
    consumption_reserves: 'Reservas de consumo',
    generation_reserves: 'Reservas de generación',
    full_reserve: 'Reserva completa',
    hc_id: 'Capacidad id',
    available_capacity: 'Capacidad disponible',
    reserve_period_month: 'Período de reserva (meses)',
    months: 'meses',
    description_optional: 'Descripción (opcional)',
    worst_case_txt: 'Mostrar peor caso',
    not_available_hcm: 'El mapa de energía de hosting solo está disponible para bajo voltaje',
    delete_confirm_reserve: '¿Está seguro de que quieres eliminar esta reserva?',
    delete_reserve_error: 'Se ha producido un error al eliminar la reserva. Por favor, inténtelo de nuevo.',
    add_reserve_error: 'Se ha producido un error al agregar la reserva. Por favor, inténtelo de nuevo.',
    warning: 'Hosting capacity no está disponible cuando la red tiene más de 5000 subredes.',
    warning_link: 'Selecciona aquí un area más pequeña.'
  },

  dt: {
    digital_twin_project: 'Proyecto Gemelo Digital',
    my_projects: 'Mis proyectos',
    new_connections: 'Nuevas conexiones',
    planning: 'Planificación',
    loading_projects: 'Cargando proyectos',
    simulation_date: 'Fecha de simulación',
    number_of_cases: 'Número de casos',
    penetration: 'penetración',
    created_on: 'Creado el',
    basic_info: 'Información básica',
    select_area: 'Seleccionar zona',
    power_constraints: 'Limitaciones de la calidad de la energía',
    max_voltage_limit: 'Máx. Límite de tensión',
    min_voltage_limit: 'Mín. Límite de tensión',
    max_voltage_unbalance: 'Máx. Desbalanceo',
    max_transformer_loading: 'Máx. Carga del transformador',
    max_current_on_lines: 'Máx. Corriente en las líneas',
    or: 'o',
    pvs_settings: 'Ajustes de panel fotovoltaico',
    percentage_of_customers_with_pv: 'Porcentaje de clientes con PF',
    initial_pv:
      'Penetración inicial de la energía fotovoltaica en la red de baja tensión',
    pv_power_steps:
      'Incrementos de los pasos de potencia FV para la simulación',
    evs_settings: 'Ajustes de vehículo eléctrico',
    ev_nominal_power: 'Potencia nominal de cargador de VE',
    ev_battery_capacity: 'Capacidad media de energía de batería de VE',
    ev_daily_distance: 'Distancia media diaria recorrida en VE',
    ev_power_factor: 'Factor de potencia de cargador de VE',
    hps_settings: 'Ajustes bombas de calor',
    n_hps: 'Número de bombas de calor',
    hp_nominal_power: 'Potencia nominal de la bomba de calor',
    hp_nominal_factor: 'Factor nominal de la bomba de calor',
    hp_cop: 'Coeficiente de rendimiento de la bomba de calor (COP)',
    generating_dt: 'Generando el gemelo digital',
    opening_dt: 'Abriendo el gemelo digital',
    delete_confirm_project: '¿Está seguro de que desea eliminar este proyecto?',
    back_to_form: 'Volver al formulario',
    loading_dt: 'Cargando Digital Twin',
    project: 'Proyecto',
    select_project: 'Seleccionar proyecto',
    simulate: 'Simular',
    step_time: 'Tiempo de paso',
    created_date: 'Fecha de creación',
    base_power: 'Potencia base',
    project_info: 'Información sobre el proyecto Digital Twin',
    remove_client: 'Eliminar cliente',
    client_removed: 'Cliente eliminado',
    change_phase: 'Cambiar fase',
    phase_connection: 'Conexión de fase',
    no_meters_in_connection_point: 'No hay medidores en este connection point',
    active: 'Activo',
    reactive: 'Reactivo',
    n_pv: 'Número de sistemas FV',
    power_factor: 'Factor de potencia',
    ev_cp: 'Potencia del cargador del VE (kW)',
    ev_ec: 'Capacidad energética de los VE (kWh)',
    ev_cpf: 'Factor de potencia del cargador EV',
    ev_t0: 'Posible carga del VE t0',
    ev_t1: 'Posible carga de VE t1',
    ev_mean: 'Media diaria de km recorridos en VE',
    daily_km: 'km diarios recorridos ETS (%)',
    peak_power: 'Potencia máxima de cada sistema (kW)',
    pnom_hp: 'Pnom. de cada Bomba de Calor (kW)',
    dates_error: 'Debe ser anterior a la fecha final'
  },
  new_connections: {
    editing: 'Editando',
    new_case: 'Nuevo caso',
    case_name: 'Nombre del caso',
    reference_case: 'Selecciona caso de referencia',
    uniform_load_scale: 'Escala de carga uniforme',
    add_element: 'Añadir elemento',
    add_pv: 'Añadir PF',
    add_hp: 'Añadir BC',
    add_ev: 'Añadir VE',
    add_ess: 'Añadir ESS',
    add_custom_profile: 'Añadir perfil personalizado',
    edit_cable: 'Editar cable',
    add_conn_point: 'Añadir acometida',
    remove_client: 'Eliminar cliente',
    change_phase: 'Cambiar fase',
    disable: 'Deshabilitar',
    pv_conn_params: 'Parámetros de conexión de PF',
    n_pvs: 'Número de PFs',
    phase_connection: 'Fase de conexión',
    installed_peak_power: 'Potencia máxima instalada',
    max_q_injection: 'Máxima inyección de potencia reactiva',
    ev_conn_params: 'Parámetros de conexión de VE',
    n_evs: 'Número de cargadores de VE',
    std: 'STD',
    ev_charging_time: 'Rango horario de carga de VE (horas)',
    hp_conn_params: 'Parámetros de conexión de bomba de calor',
    hp_power_factor: 'Factor de poder de la bomba de calor',
    current_changes: 'Cambios actuales',
    hide_details: 'Ocultar detalles',
    see_details: 'Mostrar detalles',
    edit_element: 'Editar elemento',
    quantity: 'Cantidad',
    changes: 'Cambios',
    simulating_case_msg: 'Creando y simulando caso',
    line_full: 'Línea llena de acometidas',
    toggle_state: 'Cambiar estado',
    confirm_toggle_state__msg:
      'Se va a cambiar el estado del elemento. ¿Está seguro?',
    new_connection_point: 'Nueva acometida',
    max_real_p: 'Potencia real máxima (kW)',
    num_custom_profiles: 'Número de perfiles',
    time_sampling: 'Tiempo de muestreo',
    ess_mode: 'Modo',
    ess_node: 'Nodo',
    ess_phase_conn: 'Conexión de fase',
    ess_sigid: 'ID de elemento',
    ess_sigmode: 'Sentido del flujo de corriente',
    ess_pmaxin: 'Máxima activa la potencia puede consumir',
    ess_pmaxout: 'Máxima activa la potencia puede inyectar',
    ess_qmaxin: 'Máxima reactiva la potencia puede absorber',
    ess_qmaxout: 'Máxima reactiva la potencia puede inyectable',
    ess_emax: 'Máximo kilovatio-hora',
    min_state_charge: 'Estado mínimo de carga en %',
    max_state_charge: 'Estado máxima de carga en %.',
    current_state_charge: 'Estado actual de carga en %',
    constant_active_power: 'Potencia constante activa en kw (Modo PQ)',
    constant_reactive_power: 'Potencia reactiva constante en kvar (Volt Control)',
    volt_control: 'Control Volt',
    voltage_regulation_set_point: 'Subtensión consigna de regulación',
    lower_power_limit: 'Potencia límite inferior en kW (peakshave)',
    upper_power_limit: 'Potencia límite superior en kW (peakshave)',
    band_power_limit: 'BBanda de límite de potencia en kW a activar',
    mode_and_phase: 'Modo y Fase',
    monitored_element: 'Elemento a controlar',
    max_energy_capacity: 'Capacidad de energía máxima',
    state_charge: 'Estado de carga',
    constant_active_reactive_power: 'Potencia activa y reactiva constante',
    voltage_var_parameters: 'Parámetros voltaje VAR',
    voltage_regulation_point: 'Establecer punto de regulación de voltaje',
    trigger_limit: 'Límite de activación',
    input_less_or_equal_to_high: 'El valor mínimo debe ser menor o igual al máximo',
    input_greater_or_equal_to_low: 'El valor máximo debe ser mayor o igual al mínimo',
    peak_shave_description: 'Peak Shave: Controla la potencia que fluye aguas abajo en la línea instalada aguas arriba del punto de conexión seleccionado',
    cable: 'Cable',
    change_cable_current: 'Instalado actualmente:',
    change_cable_r_km: 'R fase por km',
    change_cable_x_km: 'X fase por km',
    change_cable_r_neutral: 'R neutral por km',
    change_cable_x_neutral: 'X neutral por km',
    change_cable_maximum_current: 'Corriente máxima (A)',
    change_cable_maximum_neutral: 'Corriente neutral máxima (A)',
    disconnected: 'Desconectado'
  },
  cable_change_description: {
    id: 'ID',
    reference: 'Nombre',
    section_mm2: 'Sección (mm2)',
    ampacity_amp: 'Ampacidad (A)',
    voltage_kv: 'Voltaje (kV)',
    conductor: 'Conductor',
    capacity_kva: 'Capacidad (KVA)',
    isolation: 'Aislamiento',
    layout: 'Diseño',
    i_max_amp: 'Corriente máxima (A)',
    i_max_neutral_amp: 'Corriente neutral máxima (A)',
    r_cable_ohm_km: 'R fase por km',
    x_cable_ohm_km: 'X fase por km',
    b_cable_mho_km: 'B fase por km',
    r_neutral_ohm_km: 'R neutral por km',
    x_neutral_ohm_km: 'X neutral por km',
    b_neutral_mho_km: 'B neutral por km'
  },
  planning: {
    project_name: 'Proyecto Nombre',
    violation: 'Violación',
    violation_percentage: 'Porcentaje de violación',
    penetration_level: 'Nivel de penetración',
    level_of_penetration: 'Nivel de penetración',
    installed_capacity: 'Capacidad instalada',
    total_capacity: 'Capacidad total instalada',
    phA_undervoltage: 'Subtensión de fase A',
    phB_undervoltage: 'Subtensión de fase B',
    phC_undervoltage: 'Subtensión de fase C',
    phA_overvoltage: 'Sobretensión de fase A',
    phB_overvoltage: 'Sobretensión de fase B',
    phC_overvoltage: 'Sobretensión de fase C',
    voltage_negative_sequence: 'Tensión de secuencia negativa',
    voltage_zero_sequence: 'Tensión de secuencia cero',
    phA_overcurrent: 'Sobrecorriente de fase A',
    phB_overcurrent: 'Sobrecorriente de fase B',
    phC_overcurrent: 'Sobrecorriente de fase C',
    phN_overcurrent: 'Sobrecorriente de fase N',
    overloading_transformer: 'Transformador de sobrecarga',
    pv_info_montecarlo_simulation_quantity: 'Habrá 10 simulaciones de montecarlo, cada una formada por 500 simulaciones de corriente cada 24 horas de la fecha indicada.',
    pv_info_simulation_increased: 'En cada simulación se incrementará el pico total de potencia instalada (kWp) en la subred empezando con potencia de arranque y potencia de paso proporcionada.',
    pv_info_power_equally_shared: 'La potencia se repartirá equitativamente entre los clientes asignados aleatoriamente, pero siempre cumpliendo el nivel de penetración definido a continuación, y manteniendo su conectividad de fase.',
    pv_info_results_may_change: 'Los resultados pueden cambiar en función del día de simulación seleccionado debido al impacto de los perfiles de irradiancia.',
    ev_info_montecarlo_simulation_quantity: 'Habrá 10 simulaciones de montecarlo, cada una formada por 500 simulaciones de corriente cada 24 horas de la fecha indicada.',
    ev_info_simulation_increased: 'En cada simulación se incrementará la penetración de EVs en las subredes (%). Comenzará al 10% y finalizará al 100%.',
    ev_info_same_charger: 'El mismo cargador de VE con patrón de carga que cumpla las condiciones especificadas a continuación se instalará en clientes aleatorios que cumplan la penetración especificada en cada simulación.'
  },
  kpi: {
    apparent_power_avg: 'Potencia aparente: Avg',
    apparent_power_phase_share: 'Potencia aparente: Porcentaje de fases (Avg)',
    capacity_factor: 'Coeficiente de capacidad',
    current_avg: 'Corriente: Avg',
    current_max: 'Corriente: Max',
    current_min: 'Corriente: Min',
    current_unbalance_avg: 'Corriente: Desbalanceo (Avg)',
    current_unbalance_rate: 'Corriente: Desbalanceo (Tasa)',
    energy_losses: 'Pérdidas de energía',
    over_utilization_rate: 'Tasa de sobreutilización',
    overload_rate: 'Tasa de sobrecarga',
    ev: 'Vehiculos eléctricos',
    pv_systems: 'Sistemas fotovoltaicos',
    hps: 'Bombas de calor',
    load_factor: 'Coeficiente de carga',
    power_factor_avg: 'Factor dep potencia (Avg)',
    voltage_unbalance_avg: 'Desbalanceo de tensión (Avg)',
    voltage_unbalance_rate: 'Desbalanceo de tensión (Tasa)',
    voltage_qualified_rate: 'Tensión: Tensión noinal (Tasa)',
    voltage_overvoltage: 'Tensión: Sobretensión (Tasa)',
    voltage_undervoltage: 'Tensión: Subtensión (Tasa)',
    voltage_min: 'Tensión: Min',
    voltage_max: 'Tensión: Max',
    voltage_avg: 'Tensión: Avg',
    uniform_load_scale: 'Curva de duración de carga',
    ldc_current: 'Curva de duración de carga: Corriente',
    ldc_voltage: 'Curva de duración de carga: Potencia',
    unbalanced_factor: 'Factor de desbalanceo',
    unbalance_factor_current: 'Factor de desbalanceo de corriente',
    unbalance_factor_Voltage: 'Factor de desbalanceo de tensión',
    vl: 'Longitud de tensión',
    energy_supplied_station: 'Energy supplied (by Station)',
    energy_supplied_pv: 'Energy supplied (by PVs)',
    duration_p_out_max: 'P Out Max desde el punto de suscripción: Incidencias',
    duration_p_in_max: 'P In Max desde el punto de suscripción: Incidencias',
    duration_q_out_max: 'Q Out Max desde el punto de suscripción: Incidencias',
    duration_q_in_max: 'Q In Max desde el punto de suscripción: Incidencias',
    power_p_out_max: 'P Out Max desde el punto de suscripción: Potencia',
    power_p_in_max: 'P In Max desde el punto de suscripción: Potencia',
    power_q_out_max: 'Q Out Max desde el punto de suscripción: Potencia',
    power_q_in_max: 'Q In Max desde el punto de suscripción: Potencia'
  },
  plot: {
    phase_a: 'FaseA',
    phase_b: 'FaseB',
    phase_c: 'FaseC',
    phase_n: 'FaseN',
    power_kw: 'Potencia(kW)',
    voltage_v: 'Tensión(V)',
    current_a: 'Corriente(A)',
    time_h: 'Tiempo(h)',
    subscription_limit: 'Límites de suscripción',
    consumption_limit: 'Consumo al límite de la red',
    injection_limit: 'Inyección al límite de red',
    download: 'Descargar',
    export_data: 'Exportar gráfico de datos',
    select_phase_export: 'Seleccione las fases a exportar'
  },

  importer: {
    title: 'Importador de archivos',
    reference_column: 'Columna Referencia',
    time_column: 'Columna Instante',
    signal: 'Señal',
    measurement_column: 'Columna Medida',
    load_file: 'Cargar archivo',
    measurement_file_sent: 'Archivo de medidas enviado',
    topology: 'Topología',
    custom_profile: 'Perfil personalizado',
    parsing_file: 'Analizando archivo',
    uploading_file: 'Cargando archivo',
    only_csv_files_allowed: 'Sólo se admiten archivos .csv',
    at_least_two_columns: 'Al menos dos columnas (activo y reactivo)',
    unit_expected: 'unidad esperada',
    expected_values_between: 'Los valores deben estar en un rango entre',
    negative_values_grid_injection: 'Los valores negativos se corresponden con generación/inyección a la red',
    positive_values_consumption: 'Los valores positivos se corresponden con consumo',
    information_file: 'La información del fichero',
    has_been_successfully_uploaded: 'cargado correctamente',
    failed_uploading_file: 'Fallo al cargar el fichero',
    error_between_lines: 'Error entre las líneas'
  },

  grid_monitor: {
    select_all_stations: 'Seleccionar todas las estaciones',
    empty_tree:
      'No hay datos disponibles. Seleccione una opción válida para ver la información aquí',
    search: 'Buscar',
    assets: 'Activos',
    forecast: 'Previsión',
    date_start: 'Fecha de inicio',
    date_end: 'Fecha fin',
    aggregation: 'Agregación',
    aggregation_name: 'Nombre de agregación',
    enter_name: 'Introduzca un nombre',
    operator: 'Operador',
    send_to_plot: 'Enviar gráfico',
    sending: 'Enviando...',
    total: 'Total',
    variables: 'Variables',
    expression_calculations: 'Cálculo de expresiones',
    operation_between_factor: 'Operación entre factor',
    operation: 'Operación',
    addition: 'Summa',
    subtraction: 'Resta',
    multiplication: 'Multiplicación',
    division: 'División',
    factor: 'Factor',
    actions: 'Acciones',
    draw: 'Dibujar',
    expression: 'Expresión',
    depth: 'Profundidad',
    sampling_time: 'Tiempo de muestreo'
  },
  top_down: {
    station_name: 'Estación',
    percent_missing_data: 'Pérdida de datos (%)',
    kva_rated_power: 'Potencia nominal (kVa)',
    kva_peak_power: 'Potencia máxima (kVa)',
    percent_peak_utilization: 'Utilización pico (%)',
    kva_mean_power: 'Potencia media (kVa)',
    percent_utilization_factor: 'Factor de utilización (%)',
    percent_time_above_100_percent: 'Tiempo (%) carga>100%',
    percent_time_above_120_percent: 'Tiempo (%) carga>120%',
    percent_time_above_75_percent: 'Tiempo (%) carga>75%',
    percent_time_above_50_percent: 'Tiempo (%) carga>50%',
    kwh_total_energy_consumed: 'Energía total consumida (kWh)',
    kwh_energy_consumed_above_100_percent: 'Energía consumida carga>100% (kWh)',
    kwh_energy_consumed_above_120_percent: 'Energía consumida carga>120% (kWh)',
    count_overload_events: 'Número de eventos de sobrecarga',
    count_critical_load_events: 'Número de eventos de carga máxima',
    hours_duration_above_rated_power: 'Horas por encima de la potencia nominal',
    hours_duration_above_maximum_power: 'Horas por encima de la potencia máxima',
    load_analysis: 'Análisis de consumo',
    last_week: 'Último semana',
    last_month: 'Último mes',
    last_year: 'Último año',
    ytd: 'Año actual'
  }
}
