// Assuming you have axios installed
import axios from 'axios'
const ConfigService = (function () {
  let instance
  let _configuration = null
  async function fetchConfig () {
    try {
      const response = await axios.get('/config.json')
      console.log('Loaded configuration: ' + JSON.stringify(response.data))
      _configuration = response.data
    } catch (error) {
      console.error('Error fetching config:', error)
    }
  }
  function createInstance () {
    return {
      async getConfig (configName) {
        if (!_configuration) {
          await fetchConfig()
          if (!_configuration) {
            console.error('Configuration could not be fetched.')
          }
        }
        // console.log('getConfig(' + configName + ')=' + JSON.stringify(_configuration[configName]))
        return _configuration[configName]
      }
    }
  }
  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance()
      }
      return instance
    }
  }
})()
export default ConfigService
