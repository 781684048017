<template>
  <v-app>
    <splitpanes
      id="panelscontainer"
      class="default-theme"
      horizontal
      @resized="onSplitpanesResized($event)"
    >
      <pane>
        <v-container class="info-pane-container">
          <v-row class="info-pane-container-header">
            <v-col cols="12">
              <div class="type-element">
                {{ typeElementMayus }}
              </div>
            </v-col>
          </v-row>
          <v-row class="info-pane-container-content">
            <v-col cols="12">
              <line-details
                v-if="
                  typeElement == 'line' &&
                    elementDetails &&
                    elementDetailsUpdated
                "
                :info="elementDetails"
                :network="network"
              />
              <fuse-details
                v-if="
                  typeElement == 'fuse' &&
                    elementDetails &&
                    elementDetailsUpdated
                "
                :info="elementDetails"
                :network="network"
              />
              <connection-point-details
                v-if="
                  typeElement == 'connectionPoint' &&
                    elementDetails &&
                    elementDetailsUpdated
                "
                :info="elementDetails"
                :network="network"
              />
              <station-details
                v-if="
                  typeElement == 'station' &&
                    elementDetails &&
                    elementDetailsUpdated
                "
                :info="elementDetails"
                :network="network"
              />
              <network-details
                v-if="
                  typeElement == 'network' &&
                    idElement &&
                    elementDetails &&
                    elementDetailsUpdated
                "
                :info="elementDetails"
                :station-info="stationInfo"
                :network="network"
              />
            </v-col>
          </v-row>
        </v-container>
      </pane>
      <pane
        v-if="showPlots"
        ref="card"
        :min-size="7"
        :size="setPlotsPaneSize()"
        :max-size="setPlotsPaneSize()"
        class="plots-pane"
      >
        <PlotsPane
          id="plotspane"
          :selected-type-view-map="selectedTypeViewMap"
          :hcm-time="hcmTime"
          :element-h-c-m="elementHCM"
          :data-plot-h-c="dataPlotHC"
          :reservation-network="reservationNetwork"
          :card-height="cardHeight - 190"
          @updateMapAfterRemoveHCReservation="updateMapAfterRemoveHCReservation"
        />
      </pane>
    </splitpanes>
  </v-app>
</template>

<script>
import { get } from '@/assets/queries'
import { Splitpanes, Pane } from 'splitpanes'
import LineDetails from '@/components/GridInspection/GIPanelLineDetails.vue'
import FuseDetails from '@/components/GridInspection/GIPanelFuseDetails.vue'
import ConnectionPointDetails from '@/components/GridInspection/GIPanelConnectionPointDetails.vue'
import StationDetails from '@/components/GridInspection/GIPanelStationDetails.vue'
import NetworkDetails from '@/components/GridInspection/GIPanelNetworkDetails.vue'
import PlotsPane from '@/components/GridInspection/GIPlotsSection/GIPlots.vue'
import heightDataTableMixin from '@/mixins/heightDataTableMixin'

export default {
  name: 'GridInspectionPanel',
  components: {
    LineDetails,
    FuseDetails,
    ConnectionPointDetails,
    StationDetails,
    NetworkDetails,
    PlotsPane,
    Splitpanes,
    Pane
  },
  mixins: [heightDataTableMixin],
  props: {
    idElement: {
      type: String,
      default: ''
    },
    typeElement: {
      type: String,
      default: ''
    },
    network: {
      type: String,
      default: ''
    },
    stationInfo: {
      type: Object,
      required: false,
      default: () => {}
    },
    selectedTypeViewMap: {
      type: String,
      required: true
    },
    showPlots: {
      type: Boolean,
      default: false
    },
    elementHCM: {
      type: Object,
      default: () => {}
    },
    dataPlotHC: {
      type: Array,
      default: () => []
    },
    hcmTime: {
      type: Object,
      default: () => {}
    },
    reservationNetwork: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      elementDetails: null,
      tab: null,
      host: this.$API_HOST,
      port: this.$API_PORT,
      elementDetailsUpdated: false,
      heightPercentage: 0,
      cardHeight: 0
    }
  },
  computed: {
    typeElementMayus () {
      return this.typeElement.toUpperCase()
    },
    getPlotsPaneHeight () {
      const containerHeight =
        document.getElementById('panelscontainer').clientHeight
      const plotsHeight = document.getElementById('plotspane').clientHeight
      return (plotsHeight * 100) / containerHeight
    }
  },
  watch: {
    idElement () {
      this.elementDetailsUpdated = false
      this.idElement && this.getElementDetails()
    },
    heightPercentage () {
      this.$nextTick(() => {
        this.showPlots && this.setCardHeight()
      })
    }
  },
  mounted () {
    this.elementDetailsUpdated = false
    this.getElementDetails()

    this.$nextTick(() => {
      this.showPlots && this.setCardHeight()
    })

    window.addEventListener('resize', this.setCardHeight)
  },
  unmounted () {
    window.removeEventListener('resize', this.setCardHeight)
  },
  methods: {
    getElementDetails () {
      this.path = `${this.$API_ENDPOINT_GEN1}/ari/get_element_details`
      if (this.typeElement === 'line') {
        this.line = true
      }
      get(`${this.path}?tipo=${this.typeElement}&id_elemento=${this.idElement}`)
        .then((response) => {
          this.elementDetails = response
          this.elementDetailsUpdated = true
        })
        .catch((err) => console.error(err))
    },
    updateMapAfterRemoveHCReservation (removedReservation) {
      this.$emit('updateMapAfterRemoveHCReservation', removedReservation)
    },
    setPlotsPaneSize () {
      return this.dataPlotHC.length === 0 ? 7 : 100
    },
    onSplitpanesResized (event) {
      if (this.showPlots) {
        this.setHeightPercentage(event)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.info-container {
  padding: 0;
  background-color: #1e1e1e;
  color: #fff;
}
.info-row {
  margin: 0;
  height: calc(100vh - 60px);
  overflow: overlay;
}
.v-tabs {
  flex: 0 1 auto;
}
.splitpanes.default-theme .splitpanes__splitter,
.splitpanes.default-theme .splitpanes__pane {
  background-color: black !important;
}
#panelscontainer.default-theme {
  height: 100%;
  .info-pane-container {
    padding:0;
    background-color:#1e1e1e;
    color:#fff;
    &-header {
      margin: 0px;
      height: 50px;
      background-color: #f4c020;
      .col {
        padding: 4px 24px;
        text-align: left;
        .type-element {
          font-size: 18px;
          font-family: 'Faktum Light', sans-serif;
          color: #000;
          margin-top: 10px;
        }
      }
    }
    &-content {
      margin: 0;
      height: 90vh;
      overflow-y: auto;
      .col {
        padding: 0;
      }
    }
  }
  .plots-pane {
  overflow-y: scroll;
  background-color:#1e1e1e !important;
  }
}
</style>
