<template>
  <div>
    <v-menu
      :value="isVisible"
      :position-x="position.x"
      :position-y="position.y"
      z-index="500"
      absolute
      offset-y
    >
      <v-list
        flat
        nav
      >
        <v-list-item-group>
          <v-list-item
            v-for="item in currentMenu"
            :key="item.title"
            class="v-list-item--hover"
            dense
            @click.stop="evalItem(item)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <Component
      :is="Component"
      v-if="dialog"
      :payload="payLoad"
      :show-form="dialog"
      @closeForm="dialog = false"
    >
      <Component
        :is="ComponentChildren"
        :payload="payLoad"
        :show-form="dialog"
        @closeForm="dialog = false"
      />
    </Component>
    <v-row justify="center">
      <v-dialog
        v-model="connectivityDialog"
        persistent
        max-width="400"
        overlay-opacity="0.6"
      >
        <v-card>
          <v-card-title class="text-h5 justify-center">
            {{ connectivityType }}
          </v-card-title>
          <v-card-text>
            {{ connectivityInfo }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="#f4c020"
              text
              @click="connectivityDialog = false"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              color="#f4c020"
              text
              @click="changeFusePosition"
            >
              Confirm Switch
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { get } from '@/assets/queries'
import digitalTwinForm from '@/mixins/digitalTwinForm'

export default {
  name: 'ContextMenu',

  mixins: [digitalTwinForm],

  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      DEVICE: 'Switch',
      currentMenu: [],
      Component: null,
      payLoad: null,
      dialog: false,
      connectivityCheckAPI: `${this.$API_ENDPOINT_GEN1}/ari/connectivity-check`,
      connectivityType: '',
      connectivityInfo: '',
      connectivityDialog: false
    }
  },

  computed: {
    ...mapState({
      isVisible: ({ contextMenu }) => contextMenu.isVisible,
      position: ({ contextMenu }) => contextMenu.position,
      items: ({ contextMenu }) => contextMenu.items,
      elementInfo: ({ contextMenuElement }) => contextMenuElement.info,
      switches: (state) => state.DTChanges.Switch
    })
  },

  watch: {
    isVisible () {
      this.setMenu()
    },

    position () {
      this.setMenu()
    }
  },
  created () {
    !this.items.length && this.setMenu()
  },

  methods: {
    evalItem (item) {
      if (item.children) this.setMenu(item.children)
      else {
        this.isVisible && this.toggleSelf()
        this.setItemFn(item)
      }
    },

    setItemFn (item) {
      if (item.fn) {
        const { type } = item.fn
        type ? this[type](item) : item.fn()
      }
    },

    setMenu (newMenu) {
      this.currentMenu = (newMenu || this.menu)
        .filter((item) => {
          const isConditional = Object.hasOwn(item, 'isVisible')
          const { isVisible } = item

          return !isConditional || isVisible
        })
    },

    toggleDialog (item) {
      this.dialog = !this.dialog
      this.Component = item.fn.component
      this.payLoad = item.fn.payload
      this.ComponentChildren = this.payLoad?.children
    },

    toggleSelf () {
      this.$store.dispatch('toggleElement', 'contextMenu')
    },

    addBattery () {
      console.log('Add Battery not available')
    },

    changeFusePosition () {
      this.connectivityDialog = false
      const switchesList = this.switches
      switchesList.push(this.elementInfo.ID)
      this.$store.dispatch('setElement', { path: 'DTChanges.Switch', value: switchesList })
      this.addDevice() // add switch icon on the map
    },

    removeFuse () {
      const switchesList = this.switches
      const switchDeletedList = switchesList.filter((id) => id !== this.elementInfo.ID)
      this.$store.dispatch('setElement', { path: 'DTChanges.Switch', value: switchDeletedList })
      this.deleteDevice() // remove switch icon on the map
    },

    checkConnectivityIssues () {
      // introduce all the switches changed in the simulation case in the url as parameters
      let parametersString = '?switch[]=' + this.elementInfo.ID
      this.switches.forEach((switchID) => {
        parametersString += '&switch[]=' + switchID
      })
      const path = this.connectivityCheckAPI + parametersString
      get(path)
        .then((response) => {
          if (response.LOOP === true) {
            this.connectivityType = 'LOOP GENERATED'
            this.connectivityInfo = 'The following stations are involved: '
            response.RESULT_LOOP.forEach((station) => {
              this.connectivityInfo += station + ', '
            })
            this.connectivityInfo = this.connectivityInfo.slice(0, -2)
            this.connectivityDialog = true
          }
          if (response.ISOLATION === true && response.RESULT_ISLAND.includes(this.elementInfo.ID)) {
            this.connectivityType = 'ISOLATION GENERATED'
            this.connectivityInfo = 'The following fuses are creating an island: '
            response.RESULT_ISLAND.forEach((fuse) => {
              this.connectivityInfo += fuse + ', '
            })
            this.connectivityInfo = this.connectivityInfo.slice(0, -2)
            this.connectivityDialog = true
          }
          if (response.ISOLATION === false && response.LOOP === false) {
            this.changeFusePosition()
          }
        })
        .catch((err) => console.error(err))
    }
  }
}
</script>

<style scoped>
.v-list-item--hover:hover {
  color: white;
  background-color: #f4c020;
}
::v-deep .v-list-item__content{
    text-align:left !important;
}
::v-deep .v-list-item__link{
    text-align:left !important;
}
::v-deep .v-list-item{
    text-align:left !important;
}
</style>
