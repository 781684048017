<template>
  <div
    id="searchBox"
    class="map-searchbox"
  >
    <v-autocomplete
      v-model="value"
      :items="options"
      :label="$t('select_by_id')"
      :append-outer-icon="searchIcon"
      solo
      dense
      item-text="text"
      item-value="value"
      :filter="customFilter"
      @change="selectElement"
    >
      <template #item="data">
        <Fragment>
          <v-list-item-content>
            <v-list-item-title>
              <span style="float:left; font-size:16px; margin-right: 4px;">
                {{ data.item.text || data.item.value.id }}
              </span>
              <span style="float:right; font-family:Faktum Light, sans-serif; font-size:10px">
                {{ data.item.value.type.toUpperCase() }}
                {{ data.item.value.voltagelevel }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </Fragment>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js'
import { Fragment } from 'vue-frag'

export default {
  name: 'MapSearchBox',

  components: {
    Fragment
  },
  props: {
    networks: {
      type: Array,
      default: () => []
    },
    stations: {
      type: Array,
      default: () => []
    },
    lines: {
      type: Array,
      default: () => []
    },
    connectionPoints: {
      type: Array,
      default: () => []
    },
    fuses: {
      type: Array,
      default: () => []
    },

    voltageLevels: {
      type: Array,
      default: () => ['0', '400', '22000']
    }
  },

  data: () => ({
    searchIcon: mdiMagnify,
    value: [],
    options: []
  }),

  mounted () {
    this.voltageLevels.forEach((vl, index) => {
      if (this.$route.name === 'DigitalTwinNewConnections' && vl > 0) {
        // The voltage levels are different in Grid inspector (400/22000) and in Digital twin (0,4/22).
        vl = `${vl / 1000}`
      }

      const voltageNetworks = this.networks.find((networks) => networks.level === vl)
      voltageNetworks?.networks.forEach((networkElements) => {
        this.options.push({
          value: {
            id: networkElements.network.NAME, 
            type: 'network', 
            station: networkElements.network.NAME,
            name: networkElements.network.NAME,
            voltagelevel: vl
          },
          text: networkElements.network.NAME 
        })
      })
      this.options.push({ divider: true })

      this.stations.forEach((station) => {
        if (station[0] === vl) {
          this.options.push({
            value: {
              id: station[1], type: 'station', station: station[1], voltagelevel: vl, name: station[2]
            },
            text: station[2] 
          })
        }
      })
      this.options.push({ divider: true })

       this.lines.forEach((line) => {
        if (line[0] === vl) {
          this.options.push({
            value: {
              station: line[1], id: line[2], type: 'line', voltagelevel: vl, name: line[4] 
            },
            text: line[4]
          })
        }
      })
      this.options.push({ divider: true })

      this.connectionPoints.forEach((connectionPoint) => {
        if (connectionPoint[0] === vl) {
          this.options.push({
            value: {
              station: connectionPoint[1],
              id: connectionPoint[2],
              type: 'connection point',
              voltagelevel: vl,
              name: connectionPoint[4]
            },
            text: connectionPoint[4] 
          })
        }
      })
      this.options.push({ divider: true })

      this.fuses.forEach((fuse) => {
        if (fuse[0] === vl) {
          this.options.push({
            value: {
              station: fuse[1], id: fuse[2], type: 'fuse', voltagelevel: vl, name: fuse[4]
            },
            text: fuse[4] 
          })
        }
      })   
      const addDivider = this.voltageLevels.length - 1 > index
      if (addDivider) {
        this.options.push({ divider: true })
      }
     

    })
     const sbFuses = []
      this.networks.forEach(networkLevels => {
        const voltageLevel = networkLevels.level
        const switchBoxes = networkLevels.switchBoxes || []

        switchBoxes.forEach(switchBox => {
          const fuses = switchBox.fuses || []
          
          for (const fuse of fuses) {
            sbFuses.push({
              value: {
                id: fuse.ID,
                type: 'fuse',
                voltagelevel: voltageLevel,
                name: fuse.NAME,
              },
              text: fuse.NAME,
            })
          }
        })
      })

      this.options.push(...sbFuses)
      this.options.push({ divider: true })

      
  },
  methods: {
    customFilter(item, queryText) {
      if (!item.value) return false

      const searchText = queryText.toLowerCase();
      const name = item.value.name ? item.value.name.toLowerCase() : ''
      const id = item.value.id ? String(item.value.id).toLowerCase() : ''

      return name.includes(searchText) || id.includes(searchText)
    },
    selectElement (value) {
      const elementType = value.type === 'connection point' ? 'connectionPoint' : value.type
      this.$emit('selectElement', elementType, value.id, value.station, value.voltagelevel)
    }
  }
}
</script>

<style scoped>
.map-searchbox {
  z-index: 505;
  position: relative;

  float: right;

  height: 30px;
  line-height: 30px;
}
</style>
