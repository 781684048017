<template>
  <div>
    <l-icon
      :icon-anchor="staticAnchor"
      :class-name="getIcon"
    >
      <div class="headline">
        {{ emptyText }}
      </div>
    </l-icon>
    <l-tooltip
      :content="'<div style='+classDiv+'><br>' +
        '<p><b> Station: ' + name + '</b></p><br>' +
        '<p><b>Power (kW):</b></p>' +
        '<p>Max: ' + powerValues[0] + '</p>' +
        '<p>Min: ' + powerValues[1] + '</p> <br>' +
        '<p><b><span style='+statusColor+'>Real Time</span></b></p>' +
        '<p>Actual Power (kW): <span style='+statusColor+'>' + powerValues[2] + '</span></p><br>' +
        '<p>First measurement: <span style='+statusColor+'>' + (currentEarliestMeasurement ? new Date(currentEarliestMeasurement).toLocaleString() : null) + '</span></p>' +
        '<p>Last measurement: <span style='+statusColor+'>' + (currentLatestMeasurement ? new Date(currentLatestMeasurement).toLocaleString() : null) + '</span></p><br>' +
        '<p><b><span style='+statusColor+'>' + statusName + '</span></b></p><br>' +
        '<p><b><span style='+statusColorForecast+'>Expected for tomorrow</span></b></p>' +
        '<p><b><span style='+statusColorForecast+'>' + statusNameForecast + '</span></b></p>' +
        '<p>Forecasted peak Power (kW):<b><span style='+statusColorForecast+'>' + forecastedPeakTotalPower + '</span></b></p>' +
        '<p>Time forecasted peak:<b><span style='+statusColorForecast+'>' + (forecastedPeakMeasurementTime ? new Date(forecastedPeakMeasurementTime).toLocaleString() : null) + '</span></b></p>' +
        '</div>'"
    />
  </div>
</template>

<script>

import 'leaflet/dist/leaflet.css'
import { LIcon, LTooltip } from 'vue2-leaflet'
export default {
  name: 'TIAStationIconForecastRealTime',

  components: {
    LIcon,
    LTooltip
  },
  props: {
    location: {
      type: Array,
      default: () => ([])
    },
    uuid: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    forecastStatus: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    statusName: {
      type: String,
      default: ''
    },
    statusColor: {
      type: String,
      default: ''
    },
    statusNameForecast: {
      type: String,
      default: ''
    },
    statusColorForecast: {
      type: String,
      default: ''
    },
    currentEarliestMeasurement: {
      type: String,
      default: ''
    },
    currentLatestMeasurement: {
      type: String,
      default: ''
    },
    forecastedPeakTotalPower: {
      type: String,
      default: ''
    },
    forecastedPeakMeasurementTime: {
      type: String,
      default: ''
    },
    powerValues: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    colorStatusForecast: 'green',
    classDiv: 'background-color:#1E1E1E;color:#C3C3C3;font-weight: bold; margin-left:15px; ',
    classActual: 'color:#34AE60',
    staticAnchor: [16, 37],
    emptyText: ''
  }),

  computed: {
    getIcon () {
      const status = this.getClassByStatus(this.status)
      const forecastStatus = this.getClassByStatus(this.forecastStatus)
      const icon = 'icon-' + status + '-' + forecastStatus
      return icon
    }

  },
  methods: {
    getClassByStatus (status) {
      let clas
      if (status === '') {
        clas = 'no-device'
      } else if (status === 'not_required_flex') {
        clas = 'green'
      } else if (status === 'required_flex') {
        clas = 'yellow'
      } else if (status === 'limit_exceeded') {
        clas = 'red'
      } else if (status == '') {
        clas = 'transparent'
      }
      return clas
    }
  }
}
</script>

<style >

.circle-status-transparent {
  width: 34px;
  height: 35px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.circle-status-transparent::before {
  content: "";
  position: absolute;
  top: -29px;
  left: -14px;
  right: -19px;
  bottom: -3px;
  border-radius: 50%;
  border-color:transparent;
  background-color: transparent;
  z-index: -1;
  border: 8px solid transparent;
}

.icon-green-green {
  width: 34px;
  height: 35px;
  display: grid;
}
.icon-green-green::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0px;
  right: -27px;
  bottom: -40px;
  border-radius: 50%;
  background-color: rgba(52, 174, 96, 0.8);
  z-index: -1;
  border: 8px solid;
  animation: border-animation-green 6s infinite linear;
}
.icon-green-yellow {
  width: 34px;
  height: 35px;
  display: grid;
}
.icon-green-yellow::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0px;
  right: -27px;
  bottom: -40px;
  border-radius: 50%;
  background-color: rgba(221, 230, 117, 0.8);
  z-index: -1;
  border: 8px solid;
  animation: border-animation-green 6s infinite linear;
}
.icon-green-red {
  width: 34px;
  height: 35px;
  display: grid;
}
.icon-green-red::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0px;
  right: -27px;
  bottom: -40px;
  border-radius: 50%;
  background-color: rgba(236, 112, 99, 0.8);
  z-index: -1;
  border: 8px solid;
  animation: border-animation-green 6s infinite linear;
}
@keyframes border-animation-green  {
    0% {
      border-color: rgba(52, 174, 96, 1)
    }
    25% {
      border-color: rgba(52, 174, 96, 1);
    }
    50% {
      border-color: rgba(52, 174, 96, 1)
    }
    75% {
      border-color: rgba(52, 174, 96, 1);
    }
    100% {
      border-color: rgba(52, 174, 96, 1)
    }
  }

.icon-yellow-green {
  width: 34px;
  height: 35px;
  display: grid;
}
.icon-yellow-green::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0px;
  right: -27px;
  bottom: -40px;
  border-radius: 50%;
  background-color: rgba(52, 174, 96, 0.8);
  z-index: -1;
  border: 8px solid;
  animation: border-animation-yellow 6s infinite linear;
}
.icon-yellow-yellow {
  width: 34px;
  height: 35px;
  display: grid;
}
.icon-yellow-yellow::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0px;
  right: -27px;
  bottom: -40px;
  border-radius: 50%;
  background-color: rgba(221, 230, 117, 0.8);
  z-index: -1;
  border: 8px solid;
  animation: border-animation-yellow 6s infinite linear;
}
.icon-yellow-red {
  width: 34px;
  height: 35px;
  display: grid;
}
.icon-yellow-red::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0px;
  right: -27px;
  bottom: -40px;
  border-radius: 50%;
  background-color: rgba(236, 112, 99, 0.8);
  z-index: -1;
  border: 8px solid;
  animation: border-animation-yellow 6s infinite linear;
}
@keyframes border-animation-yellow  {
    0% {
      border-color: rgba(221, 230, 117, 1)
    }
    25% {
      border-color: rgba(221, 230, 117, 1);
    }
    50% {
      border-color: rgba(221, 230, 117, 1)
    }
    75% {
      border-color: rgba(221, 230, 117, 1);
    }
    100% {
      border-color: rgba(221, 230, 117, 1)
    }
  }

.icon-red-green {
  width: 34px;
  height: 35px;
  display: grid;
}
.icon-red-green::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0px;
  right: -27px;
  bottom: -40px;
  border-radius: 50%;
  background-color: rgba(52, 174, 96, 0.8);
  z-index: -1;
  border: 8px solid;
  animation: border-animation-red 6s infinite linear;
}
.icon-red-yellow {
  width: 34px;
  height: 35px;
  display: grid;
}
.icon-red-yellow::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0px;
  right: -27px;
  bottom: -40px;
  border-radius: 50%;
  background-color: rgba(221, 230, 117, 0.8);
  z-index: -1;
  border: 8px solid;
  animation: border-animation-red 6s infinite linear;
}
.icon-red-red {
  width: 34px;
  height: 35px;
  display: grid;
}
.icon-red-red::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0px;
  right: -27px;
  bottom: -40px;
  border-radius: 50%;
  background-color: rgba(236, 112, 99, 0.8);
  z-index: -1;
  border: 8px solid;
  animation: border-animation-red 6s infinite linear;
}
@keyframes border-animation-red  {
    0% {
      border-color: rgba(57, 81, 97, 0.9)
    }
    25% {
      border-color: rgba(236, 112, 99, 1);
    }
    50% {
      border-color: rgba(57, 81, 97, 0.9)
    }
    75% {
      border-color: rgba(236, 112, 99, 1);
    }
    100% {
      border-color: rgba(57, 81, 97, 0.9)
    }
  }
</style>
