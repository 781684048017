<template>
  <div>
    <CMLoader
      :loading="loading.isVisible"
      :message="loading.message"
    />
    <div
      :id="name"
      class="sld-container"
      :class="{scrollable: isScrollable}"
      :nodeLegend="nodeLegend"
      :legendSegmentTop="legendSegmentTop"
    />
  </div>
</template>

<script>
import clickElements from '@/mixins/map/mapElementSelectMixin'
import unclickElements from '@/mixins/map/mapElementUnselectMixin'
import CMLoader from '@/components/Common/CMLoader.vue'
import { SLD } from '@/assets/singleLineDiagram/orthogonal'
import { mapState } from 'vuex'

export default {
  name: 'SingleLineDiagram',
  components: {
    CMLoader
  },
  mixins: [clickElements, unclickElements],
  props: {
    name: { type: String },
    stationName: { type: String },
    nodeLegend: { type: Function },
    interval: { type: Number },
    legendSegmentTop: { type: Function },
    legendSegmentBottom: { type: Function },
    scrollable: { type: String },
    segmentColor: { type: Function },
    busColor: { type: Function },
    plotFlow: { type: Boolean },
    devices: { type: Object },
    level: {
      type: String,
      default: ''
    }
  },
  data () {
    // In case the user didn't defined scrollable characteristic: define it automatically
    let isScrollable = this.scrollable === 'true'

    if (this.scrollable === undefined) {
      if (this.nodeLegend) {
        isScrollable = true
      } else {
        isScrollable = false
      }
    }

    return {
      isScrollable,
      mySLD: {},
      loading: {
        isVisible: false,
        message: 'Drawing single line diagram'
      }
    }
  },
  computed: {
    ...mapState({
      HCNetworksWithLines: ({ hostingCapacity }) => hostingCapacity.HCNetworksWithLines,
      grid: (state) => state.areaGrid,
    }),
    stationId() {
      let stationSelectedId
      if (this.level < 1000 && this.level > 0 ){
        const stationSelected = this.grid.STATIONS.filter(station => station.NAME === this.stationName)
        stationSelectedId=stationSelected[0].ID
      }
      return stationSelectedId
    }
  },
  watch: {
    stationName (newVal, oldVal) { // watch it
      this.mySLD.draw(newVal)
    },
    HCNetworksWithLines (newVal, oldVal) {
      this.mySLD.refresh()
    }
  },
  mounted () {
    const svgContainer = document.getElementsByClassName('sld-container')[0]

    let isDragging = false
    let initialMouseX, initialMouseY, initialViewX, initialViewY

    svgContainer.addEventListener('mousedown', (e) => {
      isDragging = true
      initialMouseX = e.clientX
      initialMouseY = e.clientY
      initialViewX = svgContainer.scrollLeft
      initialViewY = svgContainer.scrollTop
      svgContainer.style.cursor = 'grabbing'
      e.preventDefault() // Prevent text selection during drag
    })

    document.addEventListener('mousemove', (e) => {
      if (!isDragging) return
      const deltaX = e.clientX - initialMouseX
      const deltaY = e.clientY - initialMouseY

      // Calculate the maximum allowed scroll values
      const maxScrollX = svgContainer.scrollWidth - svgContainer.clientWidth
      const maxScrollY = svgContainer.scrollHeight - svgContainer.clientHeight

      // Apply scroll changes within boundaries
      const newScrollX = Math.min(Math.max(initialViewX - deltaX, 0), maxScrollX)
      const newScrollY = Math.min(Math.max(initialViewY - deltaY, 0), maxScrollY)

      svgContainer.scrollLeft = newScrollX
      svgContainer.scrollTop = newScrollY
    })

    document.addEventListener('mouseup', () => {
      isDragging = false
      svgContainer.style.cursor = 'grab'
    })

    // Prevent text selection when dragging
    svgContainer.addEventListener('selectstart', (e) => {
      if (isDragging) {
        e.preventDefault()
      }
    })

    this.toggleLoading()
    if (this.plotFlow === undefined) {
      this.plotFlow = true
    }
    this.mySLD = new SLD(this.$API_ENDPOINT_GEN1, this.name,
      this.isScrollable, this.nodeLegend, this.legendSegmentTop, this.legendSegmentBottom,
      this.segmentColor, this.busColor, this.plotFlow, this.devices)
    this.mySLD.leftClickFunc = (id, type) => {
      if (type === 'line') {
        this.clickElements(this.stationName, id, 'line')
        this.click(this.stationName, id, 'line')
      } else if (type === 'load') {
        this.clickElements(this.stationName, id, 'connectionPoint') // changed from cgp
        this.click(this.stationName, id, 'connectionPoint') // changed from cgp
      } else if (type === 'transformer') {
        this.clickElements(this.stationName, id, 'station')
        this.click(this.stationName, this.stationId, 'station')
      } else {
        this.clickElements(this.stationName, id, 'Fuse')
        this.click(this.stationName, id, 'Fuse')
      }
    }
    this.mySLD.draw(this.stationName).then(() => {
      this.toggleLoading()
    }).catch((err) => {
      console.warn(err)
      this.toggleLoading()
    })

    if (this.interval) {
      setInterval(() => { this.mySLD.refresh() }, this.interval)
    }
  },
  methods: {
    refreshSLD () {
      this.mySLD.refresh()
    },
    click (station, elementId, elementType) {
      this.$emit('click', this.level, station, elementId, elementType)

      if (this.isContextMenu) {
        this.$store.dispatch('toggleElement', 'contextMenu')
      }
    },
    toggleLoading () {
      this.loading.isVisible = !this.loading.isVisible
    }
  }
}
</script>

<style scoped>
  @import '../../assets/icons/style.css';
  @import '../../assets/singleLineDiagram/orthogonal.css';
</style>
