import { plexiRequest } from '.'

export const getVersion = async () => {
  // return plexiRequest({ url: 'auth/version-info/' })
  // TODO: This is hardcoded. We still need the real call to the back
  return {
    gui: process.env.VUE_APP_VERSION,
    aritatari: process.env.VUE_APP_VERSION,
    tia: process.env.VUE_APP_VERSION
  }
}
