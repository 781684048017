<template>
  <div>

    <div class="plotStation">

      <Plotly
        :data="getDataPlot"
        :layout="getLayoutPlot"
        :display-mode-bar="false"
        :displaylogo="false"
      />

    </div>
  </div>
</template>

<script>
import { Plotly } from 'vue-plotly'
import { mdiDownload } from '@mdi/js'
import TIAMonitoringExportPlotDataForm from '@/components/TIA/TIAMonitoring/TIAMonitoringExportPlotDataForm.vue'

export default {
  name: 'TIAMonitoringPlotStation',

  components: {
    Plotly,
    TIAMonitoringExportPlotDataForm
  },
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    doe: {
      type: Array,
      default: () => ([])
    }

  },
  data: () => ({
    downloadIcon: mdiDownload,
    downloadDialog: false,
    heightPlot: null,
    nonFlexValue: [],
    flexValue: [],
    outLimitsValue: [],
    DOEValue: [],
    powerLimit:[],
    horizontalPlane: null,
    consolidatedPhase: [],
    data: null,
    flexMargin:[],

  }),

  computed: {
    getDataPlot () {
      const proxyDOE = new Proxy(this.doe, {
        // Handler del proxyData
        get (target, prop) {
          return target[prop]
        }
      })
      // const proxyData = this.data.data
      // Variables for Plot
      let datos = []
      let measurement
      let time =[]
      let totalActivePower =[]
      let flexActivePower=[]
      let timeDOE=[]
      let maxConsumptionDOE=[] 
        if (this.data.time.length > 0){
          time = this.data.time.map(t => new Date(t))
        }
        if (this.data.dso_power_avg_kw.length > 0){
          totalActivePower = this.data.dso_power_avg_kw
        }
        let timeLength = time.length;
        let powerLength = this.data.dso_power_avg_kw.length;
        let diff = timeLength - powerLength;
        if (diff > 0) {
          for (let i = 0; i < diff; i++) {
              totalActivePower.push(null);
          }
        }
        // console.log(totalActivePower)
        //Total Power Consumed
        measurement = {
          x: time,
          y: totalActivePower,
          type: 'lines',
          mode: 'lines',
          name: "Total Active Power",
          line: {
            color: 'rgba(171, 235, 198 , 0.7)',
            dash: 'solid',
            width: 3
          },
          visible: true
        }        
        datos.push(measurement)

        //Represent DERs
        flexActivePower = this.getSeries(this.data.der_power_avg_kw)
        time = this.data.time.map(t => new Date(t))
        
        flexActivePower.forEach((der) => {       
          let powerDER = []
          let color1 = this.getRandomNumberInRange(0,255)
          let color2 = this.getRandomNumberInRange(100,200)
          powerDER = der.data          
          measurement = {
            x: time,
            y: powerDER,
            type: 'lines',
            mode: 'lines',
            name: `DER-${der.name}`,
            line: {
              color: `rgba(${color1}, ${color2}, 194, 0.7)`,
              dash: 'solid',
              width: 3
            },
            visible: true
          }        
          datos.push(measurement)
        })


      // DOES ??
      if (proxyDOE.length >0){
        proxyDOE.forEach((measur) => {
          timeDOE.push(new Date(measur.time_start))          
          maxConsumptionDOE.push(measur.max_consumption)
        })
        // ADD DOE
        measurement = {
          x: timeDOE,
          y: maxConsumptionDOE,
          type: 'bar',
          mode: 'lines',
          name: 'DOE',
          line: {
            shape: 'hvh'
          },
          visible: true,
          marker: {
            color: 'rgba(158,202,225, 0.5)',
            opacity: 1,
            line: {
              color: 'rgb(158,202,225)',
              width: 1
            }
          },
          offset: 1,
          // width: 0.8, // Adjust the width of the bar
        }
        datos.push(measurement)
      }

      this.getDataCSV(datos)
      return datos
    },

    getLayoutPlot () {
      const layout = {
        title: '',
        bargap :0,
        legend: {
          orientation: 'h',
          entrywidth: 200,
          valign: 'top',
          yanchor: 'bottom',
          y: 1.02,
          xanchor: 'right',
          x: 1,
          bgcolor: '#272727',
          font: {
            size: '12',
            family: 'Faktum Light'
          }

        },
        
        barmode: 'bar',
        height: this.heightPlot,
        margin: {
          l: 60,
          r: 10,
          b: 100,
          t: 0,
          pad: 5
        },
        xaxis: {
          title: 'Time (h)',
          linecolor: '#fff',
          nticks: 10,
          gridcolor: '#595959',
          showspikes: true,
          spikethickness: 2,
          spikedash: 'dot',
          spikecolor: '#fff',
          spikemode: 'across',
          tickangle: 0,
          tickfont: {
            size: 12
          }
        },
        yaxis: {
          title: '(kW)',
          linecolor: '#fff',
          gridcolor: '#595959'
        },
        paper_bgcolor: '#272727',
        plot_bgcolor: '#272727',
        font: {
          color: '#fff',
          family: 'Faktum Light'
        },
        hovermode: 'x',
        modebar: {
          activecolor: '#f4c020',
          color: '#fff',
          remove: ['zoomin2d', 'zoomout2d', 'resetscale2d']
        }
      }

      return layout
    }

  },

  mounted () {
    this.heightPlot = window.innerHeight * 0.37
  },
  methods: {
    getRandomNumberInRange(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    getSeries(data) {
        const series = [];

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                series.push({
                    name: key,
                    data: data[key]
                });
            }
        }

        return series
    },

     getDayTime(data) {
      const result = []
      const maxPower = this.$store.state.TIA.TIAMap.maxPowerStation      
      data[0].forEach((measur) => {
        result.push(new Date(measur.time))
        this.powerLimit.push(maxPower)
      })
      data[1].forEach((measur) => {
        result.push(new Date(measur.time))
        this.powerLimit.push(maxPower)
      })
      return result
    },
    minusArrays (array1, array2) {
      if (array1.length !== array2.length) {
        throw new Error('Arrays must have the same lenght.')
      }
      const resultado = []
      for (let i = 0; i < array1.length; i++) {
        const resta = array1[i] - array2[i]
        resultado.push(resta)
      }

      return resultado
    },
    calculateFlexAndOutLimits (nonFlex, flex, maxPwr) {
      const flexValues = []
      const DOEValues = []
      const outLimitsValues = []

      for (let i = 0; i < nonFlex.length; i++) {
        const totalPower = nonFlex[i] + flex[i]

        if (totalPower <= maxPwr[i]) {
          // If the sum of nonFlex and flex is less than or equal to maxPwr, the value is equal to the value of flex.
          flexValues.push(flex[i])
          DOEValues.push(maxPwr[i] - totalPower)
          outLimitsValues.push(0)
        } else {
          // If the sum of nonFlex and flex is greater than maxPwr, we calculate the value of flexValues and outLimitsValues
          flexValues.push(maxPwr[i] - nonFlex[i])
          DOEValues.push(0)
          outLimitsValues.push(totalPower - maxPwr[i])
        }
      }
      this.outLimitsValue = outLimitsValues
      this.DOEValue = DOEValues
      return flexValues
    },
    calculateDOEPast(powerLimit, nonFlexPower, totalPower){  
      let DOE = []   
      let minSize = Math.min(powerLimit.length, nonFlexPower.length)

      for (let i = 0; i < minSize; i++) {
        if (totalPower[i]>powerLimit[i]){
          DOE.push(powerLimit[i] - nonFlexPower[i])                
          this.flexMargin.push(0)
        }else{
          DOE.push(0)
          this.flexMargin.push(powerLimit[i] - nonFlexPower[i])
          
        }
        
      }
      return DOE
    },
    calculateDOEFuture(powerLimit, nonFlexPower, totalPower){  
      let DOE = [] 
      for (let i = 0; i < nonFlexPower.length; i++) {
        if (totalPower[i]>powerLimit[i]){
          DOE.push(powerLimit[i] - nonFlexPower[i])                
          this.flexMargin.push(0)
        }else{
          this.flexMargin.push(powerLimit[i] - nonFlexPower[i])
          DOE.push(0)
        }   
      }
      return DOE
    },
    openDownload() {
      this.downloadDialog = true
    },
    closeDownload() {
      this.downloadDialog = false
    },
    getDataCSV(allData) {
      const extractedData = allData.map(obj => ({
        name: obj.name,
        x: obj.x,
        y: obj.y
      }))
    this.data = extractedData
    },

  }
}
</script>

<style scoped>
.plotStation{
  margin-top: 20px!important;
}
.button-download-supply {
  position: absolute;
  top: 455px;
  right: auto;
}
.button-download-icon{
  color:white;
  margin-left:47px;
}
</style>