<template>
  <v-form
    ref="newProjectForm"
    v-model="valid"
    lazy-validation
  >
    <v-row style="margin-top: 5px">
      <v-expansion-panels
        v-model="panel"
        accordion
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header>{{ $t('dt.basic_info') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newProjectName"
                  :rules="VALIDATIONS['newProjectName']"
                  data-cy="dt-new-project-name"
                  :label="$t('name')"
                  type="text"
                  dense
                />
              <div 
                v-if="feedback"
                class="project-feedback"
              >
                {{ feedback }}
              </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="newProjectFrom"
                  :rules="dateRules"
                  data-cy="dt-new-project-from"
                  :label="$t('start_date')"
                  type="date"
                  dense
                  required
                />
                <div 
                  v-if="!correctDates && newProjectFrom && newProjectTo"
                  class="project-feedback"
                >
                  {{ $t('dt.dates_error') }}
                </div>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-if="!showPlanning"
                  v-model="newProjectTo"
                  :rules="dateRules"
                  data-cy="dt-new-project-to"
                  :label="$t('end_date')"
                  type="date"
                  dense
                  required
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <v-autocomplete
                  v-model="value"
                  :items="networkList"
                  item-text="NAME"
                  :search-input.sync="search"
                  data-cy="dt-new-project-stations"
                  return-object
                  small-chips
                  deletable-chips
                  dense
                  clearable
                  allow-overflow
                  hide-selected
                  multiple
                  :label="$t('grid.stations')"
                  placeholder="Select networks"
                >
                  <template #prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="selectAllNetworks"
                    >
                      <v-list-item-content style="text-align:left;">
                        <v-list-item-title>{{ $t('select_all') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-1" />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                style="padding: 0"
              >
                {{ $t('dt.or') }}
              </v-col>
              <v-col
                cols="4"
                style="margin-left: 105px"
              >
                <v-btn @click="selectNetworksOnMap">
                  {{ $t('dt.select_area') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>{{ $t('dt.power_constraints') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="VMax"
                  dense
                  :label="$t('dt.max_voltage_limit')"
                  type="number"
                  suffix="p.u."
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="VMin"
                  dense
                  :label="$t('dt.min_voltage_limit')"
                  type="number"
                  suffix="p.u."
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="VMaxUnbalance"
                  dense
                  :label="$t('dt.max_voltage_unbalance')"
                  type="number"
                  suffix="p.u."
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="overLoading"
                  dense
                  :label="$t('dt.max_transformer_loading')"
                  type="number"
                  suffix="p.u."
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="overCurrent"
                  dense
                  :label="$t('dt.max_current_on_lines')"
                  type="number"
                  suffix="p.u."
                  required
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row
        style="margin-top: 30px"
        align="center"
        justify="center"
      >
        <v-col self-align="center">
          <v-btn-toggle
            v-model="toggleTypeOfAssesment"
            dense
            rounded
            mandatory
          >
            <v-btn> {{ $t('dt.new_connections') }} </v-btn>
            <v-btn> {{ $t('dt.planning') }} </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-row>
    <PlanningForm v-if="showPlanning" />
    <v-row>
      <v-col self-align="center">
        <v-btn
          v-if="showPlanning"
          data-cy="dt-create-project"
          color="#f4c020"
          text
          :disabled="!valid"
          @click="createNewProjectPlanning()"
        >
          {{ $t('ok') }}
        </v-btn>
        <v-btn
          v-else
          data-cy="dt-create-project"
          color="#f4c020"
          text
          :disabled="!valid || feedback || (!correctDates && newProjectFrom && newProjectTo)"
          @click="createNewProject()"
        >
          {{ $t('ok') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import PlanningForm from '@/components/DigitalTwin/Forms/DTNewProjectPlanningForm.vue'
import { mapState } from 'vuex'
import { VALIDATIONS } from '@/store/constants'
import DigitalTwinCreator from '@/assets/DigitalTwin/DigitalTwinCreator'
import i18n from '@/plugins/i18n'

export default {
  name: 'CreateForm',
  components: {
    PlanningForm
  },
  props: {
    networkList: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      panel: [0],
      newProjectName: null,
      newProjectFrom: null,
      newProjectTo: null,
      StarT: null,
      StepC: null,
      value: null,
      VMax: 1.10,
      VMin: 0.90,
      VMaxUnbalance: 0.02,
      overLoading: 1.25,
      overCurrent: 1.3,
      NegSeqMax: 0.9,
      ZeroSeqMax: 0.9,
      showPlanning: false,
      toggleTypeOfAssesment: 0,
      valid: true,
      dateRules: [
        (v) => !!v || 'Date is required'
      ],
      search: null,
      simulationMode: null,
      simulationModeOptions: ['Coupled', 'Decoupled'],
      projectNames: []
    }
  },
  computed: {
    ...mapState({
      selectedNetworks: (state) => state.projectNetworks,
      configEV: (state) => state.planningConfigEV,
      configPV: (state) => state.planningConfigPV,
      planningType: (state) => state.planningType
    }),

    VALIDATIONS () {
      return VALIDATIONS
    },
    selectedAllNetworks () {
      return this.value.length === this.networkList.length
    },
    selectedSomeNetworks () {
      return this.value.length >= 0 && !this.selectedAllNetworks
    },
    correctDates () {
      return (new Date(this.newProjectTo).getTime() - new Date(this.newProjectFrom).getTime()) >= 0
    }
  },
  watch: {
    value () {
      this.$store.dispatch('setElement', { path: 'projectNetworks', value: this.value })
      this.search = ''
    },
    toggleTypeOfAssesment (typeOfAssesment) {
      if (typeOfAssesment === 0) {
        this.showPlanning = false
      } else {
        this.showPlanning = true
      }
    },
    toggleTypeOfDevices (typeOfDevice) {
      if (typeOfDevice === 0) {
        this.showPlanningPVs = true
        this.showPlanningEVs = false
        this.showPlannningHPs = false
      } else if (typeOfDevice === 1) {
        this.showPlanningPVs = false
        this.showPlanningEVs = true
        this.showPlannningHPs = false
      } else {
        this.showPlanningPVs = false
        this.showPlanningEVs = false
        this.showPlannningHPs = true
      }
    },
    newProjectName (val) {
      this.feedback = this.projectNames.includes(val)
        ? i18n.t('validations.project_name_exists')
        : false
    }
  },
  mounted () {
    this.value = this.selectedNetworks
    this.getProjects()
  },

  methods: {
    async getProjects () {
      const dtc = new DigitalTwinCreator(this.$HEADER_HTTP, this.$API_HOST, this.$API_PORT,
        () => {}, console.error,
        console.warn,
        this.$DEBUG)
      const projectsList = await dtc.getProjectList()
      this.projectNames = projectsList.map(item => item.name)
    },
    createNewProject () {
      const fecha = new Date(this.newProjectFrom)
      const desfase = fecha.getTimezoneOffset()
      const FinalT = new Date(this.newProjectTo).getTime()

      this.StarT = new Date(this.newProjectFrom).getTime()
      this.StepC = Math.round((FinalT - this.StarT) / (1000 * 60 * 60))

      if (this.$refs.newProjectForm.validate() && this.value.length > 0) {
        const sim = {
          StartTime: this.StarT + (desfase * 60 * 1000),
          StepCount: this.StepC + 24,
          BasePower: 2,
          InitDate: this.newProjectFrom,
          EndDate: this.newProjectTo
          // SimMode: this.simulationMode
        }
        const quality = {
          Vmax: parseFloat(this.VMax),
          Vmin: parseFloat(this.VMin),
          NegSeqMax: parseFloat(this.VMaxUnbalance),
          ZeroSeqMax: parseFloat(this.VMaxUnbalance),
          OverCurrentRatio: parseFloat(this.overCurrent),
          OverLoadingRatio: parseFloat(this.overLoading)
        }
        const networks = []
        this.value.forEach((element) => {
          networks.push(element.NAME)
        })
        this.$emit('createNewProject', this.newProjectName.trim(), networks, sim, quality)
      }
    },
    createNewProjectPlanning () {
      const fecha = new Date(this.newProjectFrom)
      const desfase = fecha.getTimezoneOffset()

      this.StarT = new Date(this.newProjectFrom).getTime()

      if (this.$refs.newProjectForm.validate() && this.value.length > 0) {
        let mode = 1
        if (this.planningType === 'PV') {
          mode = 2
        }
        const sim = {
          StartTime: this.StarT + (desfase * 60 * 1000),
          StepCount: 24,
          SimMode: mode

        }
        const quality = {
          EV_PenetrationStart: 10.0,
          EV_PenetrationEnd: 100.0,
          EV_PenetrationStep: 10.0,
          PV_Penetration: this.configPV.percentage,
          PV_PowerStart: this.configPV.initialPower,
          PV_PowerStep: this.configPV.powerSteps,
          PV_PowerFactor: 1.0,
          Vmax: parseFloat(this.VMax),
          Vmin: parseFloat(this.VMin),
          NegSeqMax: parseFloat(this.VMaxUnbalance),
          ZeroSeqMax: parseFloat(this.VMaxUnbalance),
          OverCurrentRatio: parseFloat(this.overCurrent),
          OverLoadingRatio: parseFloat(this.overLoading)
        }
        const networks = []
        this.value.forEach((element) => {
          networks.push(element.NAME) /* Check with backed that montecarlo receives NAME */
        })
        const configParams = this.configEV
        this.$emit('createNewProjectPlanning', this.newProjectName.trim(), networks, sim, quality, configParams)
      }
    },
    selectNetworksOnMap () {
      this.$emit('selectArea')
    },
    selectAllNetworks () {
      this.$nextTick(() => {
        if (this.selectedAllNetworks) {
          this.value = []
        } else if (this.selectedSomeNetworks) {
          this.value = this.networkList
        }
      })
    }
  }
}
</script>
<style>
.project-feedback {
  text-align: left;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: -15px;
}
</style>
<style scoped>
::v-deep .v-list-item__content{
    text-align:left !important;
}
</style>