import i18n from '@/plugins/i18n'

export default {
  methods: {
    async lineKpis (dt, cases, Frame1, Frame2, idElement, caseSelected) {
      // op1 = 0 for actual values; op1 = 256 for pu values
      const op1 = 0
      let LineIndex
      // -----
      const KPISLineIMin = []
      const KPISLineIMax = []
      const KPISLineIavg = []
      const KPISLineCapFac = []
      const KPISLineOverUtiRate = []
      const KPISLineOverloadRate = []
      const KPISLineIunbRate = []
      const KPISLineAvgIunb = []
      const KPISLineEnergyLosses = []
      const KPISLineAvgS = []
      const KPISLineAvgSunb = []
      const dataForKPItable = []
      function KPIsLineImin(Index, option1) {
        const op2 = 0 // for minimum between 3 phases
        const op = option1 + op2
        KPISLineIMin[Index] = {}
        KPISLineIMin[Index].KPI = i18n.t('kpi.current_min')
        KPISLineIMin[Index].unit = 'A'
        KPISLineIMin[Index].caseName = cases[Index].name
        KPISLineIMin[Index].phase = {}
        // For the three  phase --------------------------------------------------------
        KPISLineIMin[Index].phase.threePhase = {}
        KPISLineIMin[Index].phase.threePhase.value = dt.Results.GetLineImin(cases[Index].idx,
          Frame1, Frame2, LineIndex, op)
        KPISLineIMin[Index].phase.threePhase.vioCount = null
        KPISLineIMin[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISLineIMin[Index].phase.A = {}
        KPISLineIMin[Index].phase.A.value = dt.Results.GetLinePhaseImin(cases[Index].idx,
          Frame1, Frame2, LineIndex, 0)
        KPISLineIMin[Index].phase.A.vioCount = null
        KPISLineIMin[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISLineIMin[Index].phase.B = {}
        KPISLineIMin[Index].phase.B.value = dt.Results.GetLinePhaseImin(cases[Index].idx,
          Frame1, Frame2, LineIndex, 1)
        KPISLineIMin[Index].phase.B.vioCount = null
        KPISLineIMin[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISLineIMin[Index].phase.C = {}
        KPISLineIMin[Index].phase.C.value = dt.Results.GetLinePhaseImin(cases[Index].idx,
          Frame1, Frame2, LineIndex, 2)
        KPISLineIMin[Index].phase.C.vioCount = null
        KPISLineIMin[Index].phase.C.vioInstants = null
        // ---For the neutral ------------------------------------------------------------------
        KPISLineIMin[Index].phase.N = {}
        KPISLineIMin[Index].phase.N.value = dt.Results.GetLinePhaseImin(cases[Index].idx,
          Frame1, Frame2, LineIndex, 3)
        KPISLineIMin[Index].phase.N.vioCount = null
        KPISLineIMin[Index].phase.N.vioInstants = null
        // console.log('KPISLineIMin', KPISLineIMin);
      }
      function KPIsLineImax(Index, option1) {
        const op2 = 1 // for maximum between 3 phases
        const op = option1 + op2
        KPISLineIMax[Index] = {}
        KPISLineIMax[Index].KPI = i18n.t('kpi.current_max')
        KPISLineIMax[Index].unit = 'A'
        KPISLineIMax[Index].caseName = cases[Index].name
        KPISLineIMax[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISLineIMax[Index].phase.threePhase = {}
        KPISLineIMax[Index].phase.threePhase.value = dt.Results.GetLineImax(cases[Index].idx,
          Frame1, Frame2, LineIndex, op)
        KPISLineIMax[Index].phase.threePhase.vioCount = null
        KPISLineIMax[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISLineIMax[Index].phase.A = {}
        KPISLineIMax[Index].phase.A.value = dt.Results.GetLinePhaseImax(cases[Index].idx,
          Frame1, Frame2, LineIndex, 0)
        KPISLineIMax[Index].phase.A.vioCount = null
        KPISLineIMax[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISLineIMax[Index].phase.B = {}
        KPISLineIMax[Index].phase.B.value = dt.Results.GetLinePhaseImax(cases[Index].idx,
          Frame1, Frame2, LineIndex, 1)
        KPISLineIMax[Index].phase.B.vioCount = null
        KPISLineIMax[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISLineIMax[Index].phase.C = {}
        KPISLineIMax[Index].phase.C.value = dt.Results.GetLinePhaseImax(cases[Index].idx,
          Frame1, Frame2, LineIndex, 2)
        KPISLineIMax[Index].phase.C.vioCount = null
        KPISLineIMax[Index].phase.C.vioInstants = null
        // ---For the neutral ------------------------------------------------------------------
        KPISLineIMax[Index].phase.N = {}
        KPISLineIMax[Index].phase.N.value = dt.Results.GetLinePhaseImax(cases[Index].idx,
          Frame1, Frame2, LineIndex, 3)
        KPISLineIMax[Index].phase.N.vioCount = null
        KPISLineIMax[Index].phase.N.vioInstants = null
        // console.log('KPISLineIMax', KPISLineIMax);
      }
      function KPIsLineIavg(Index, option1) {
        const op2 = 2 // for average between 3 phases
        const op = option1 + op2
        KPISLineIavg[Index] = {}
        KPISLineIavg[Index].KPI = i18n.t('kpi.current_avg')
        KPISLineIavg[Index].unit = 'A'
        KPISLineIavg[Index].caseName = cases[Index].name
        KPISLineIavg[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISLineIavg[Index].phase.threePhase = {}
        KPISLineIavg[Index].phase.threePhase.value = dt.Results.GetLineIavg(cases[Index].idx,
          Frame1, Frame2, LineIndex, op)
        KPISLineIavg[Index].phase.threePhase.vioCount = null
        KPISLineIavg[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISLineIavg[Index].phase.A = {}
        KPISLineIavg[Index].phase.A.value = dt.Results.GetLinePhaseIavg(cases[Index].idx,
          Frame1, Frame2, LineIndex, 0)
        KPISLineIavg[Index].phase.A.vioCount = null
        KPISLineIavg[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISLineIavg[Index].phase.B = {}
        KPISLineIavg[Index].phase.B.value = dt.Results.GetLinePhaseIavg(cases[Index].idx,
          Frame1, Frame2, LineIndex, 1)
        KPISLineIavg[Index].phase.B.vioCount = null
        KPISLineIavg[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISLineIavg[Index].phase.C = {}
        KPISLineIavg[Index].phase.C.value = dt.Results.GetLinePhaseIavg(cases[Index].idx,
          Frame1, Frame2, LineIndex, 2)
        KPISLineIavg[Index].phase.C.vioCount = null
        KPISLineIavg[Index].phase.C.vioInstants = null
        // ---For the neutral ------------------------------------------------------------------
        KPISLineIavg[Index].phase.N = {}
        KPISLineIavg[Index].phase.N.value = dt.Results.GetLinePhaseIavg(cases[Index].idx,
          Frame1, Frame2, LineIndex, 3)
        KPISLineIavg[Index].phase.N.vioCount = null
        KPISLineIavg[Index].phase.N.vioInstants = null
        // console.log('KPISLineIavg', KPISLineIavg);
      }
      function KPIsCapacityFactor(Index, option1) {
        const op2 = 2 // for average between 3 phases
        const op = option1 + op2
        KPISLineCapFac[Index] = {}
        KPISLineCapFac[Index].KPI = i18n.t('kpi.capacity_factor')
        KPISLineCapFac[Index].unit = '%'
        KPISLineCapFac[Index].caseName = cases[Index].name
        KPISLineCapFac[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISLineCapFac[Index].phase.threePhase = {}
        KPISLineCapFac[Index].phase.threePhase.value = dt.Results.GetLineCapFac(cases[Index].idx,
          Frame1, Frame2, LineIndex, op)
        KPISLineCapFac[Index].phase.threePhase.vioCount = null
        KPISLineCapFac[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISLineCapFac[Index].phase.A = {}
        KPISLineCapFac[Index].phase.A.value = dt.Results.GetLinePhaseCapFac(cases[Index].idx,
          Frame1, Frame2, LineIndex, 0)
        KPISLineCapFac[Index].phase.A.vioCount = null
        KPISLineCapFac[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISLineCapFac[Index].phase.B = {}
        KPISLineCapFac[Index].phase.B.value = dt.Results.GetLinePhaseCapFac(cases[Index].idx,
          Frame1, Frame2, LineIndex, 1)
        KPISLineCapFac[Index].phase.B.vioCount = null
        KPISLineCapFac[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISLineCapFac[Index].phase.C = {}
        KPISLineCapFac[Index].phase.C.value = dt.Results.GetLinePhaseCapFac(cases[Index].idx,
          Frame1, Frame2, LineIndex, 2)
        KPISLineCapFac[Index].phase.C.vioCount = null
        KPISLineCapFac[Index].phase.C.vioInstants = null
        // console.log('KPISLineCapFac', KPISLineCapFac);
      }
      function KPIsLineOverUtiRate(Index, option1) {
        const op2 = 2 // for average between 3 phases
        const op = option1 + op2
        KPISLineOverUtiRate[Index] = {}
        KPISLineOverUtiRate[Index].KPI = i18n.t('kpi.over_utilization_rate')
        KPISLineOverUtiRate[Index].unit = '%'
        KPISLineOverUtiRate[Index].caseName = cases[Index].name
        KPISLineOverUtiRate[Index].phase = {}
        // For the three phase --------------------------------------------------------
        let vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISLineOverUtiRate[Index].phase.threePhase = {}
        KPISLineOverUtiRate[Index].phase.threePhase.value = dt.Results
          .GetLineOverUti(cases[Index].idx, Frame1, Frame2, LineIndex, op, vioArray)[0]
        KPISLineOverUtiRate[Index].phase.threePhase.vioInstants = vioArray
        KPISLineOverUtiRate[Index].phase.threePhase.vioCount = dt.Results
          .GetLineOverUti(cases[Index].idx, Frame1, Frame2, LineIndex, op, vioArray)[1]
        // --For phase A ---------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        let vioArraypu = new Uint32Array(Frame2 - Frame1 + 1)
        KPISLineOverUtiRate[Index].phase.A = {}
        KPISLineOverUtiRate[Index].phase.A.value = dt.Results.GetLinePhaseOverUti(cases[Index].idx,
          Frame1, Frame2, LineIndex, 0, vioArray, vioArraypu)[0]
        KPISLineOverUtiRate[Index].phase.A.vioInstants = vioArray
        KPISLineOverUtiRate[Index].phase.A.vioCount = dt.Results
          .GetLinePhaseOverUti(cases[Index].idx, Frame1, Frame2, LineIndex, 0, vioArray, vioArraypu)[1]
        // --For phase B---------------------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        vioArraypu = new Uint32Array(Frame2 - Frame1 + 1)
        KPISLineOverUtiRate[Index].phase.B = {}
        KPISLineOverUtiRate[Index].phase.B.value = dt.Results.GetLinePhaseOverUti(cases[Index].idx,
          Frame1, Frame2, LineIndex, 1, vioArray, vioArraypu)[0]
        KPISLineOverUtiRate[Index].phase.B.vioInstants = vioArray
        KPISLineOverUtiRate[Index].phase.B.vioCount = dt.Results
          .GetLinePhaseOverUti(cases[Index].idx, Frame1, Frame2, LineIndex, 1, vioArray, vioArraypu)[1]
        // ---For phase C-----------------------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        vioArraypu = new Uint32Array(Frame2 - Frame1 + 1)
        KPISLineOverUtiRate[Index].phase.C = {}
        KPISLineOverUtiRate[Index].phase.C.value = dt.Results.GetLinePhaseOverUti(cases[Index].idx,
          Frame1, Frame2, LineIndex, 2, vioArray, vioArraypu)[0]
        KPISLineOverUtiRate[Index].phase.C.vioInstants = vioArray
        KPISLineOverUtiRate[Index].phase.C.vioCount = dt.Results
          .GetLinePhaseOverUti(cases[Index].idx, Frame1, Frame2, LineIndex, 2, vioArray, vioArraypu)[1]
        // console.log('KPISLineOverUtiRate', KPISLineOverUtiRate);
      }
      function KPIsLineOverloadRate(Index, option1) {
        const op2 = 2 // for average between 3 phases
        const op = option1 + op2
        KPISLineOverloadRate[Index] = {}
        KPISLineOverloadRate[Index].KPI = i18n.t('kpi.overload_rate')
        KPISLineOverloadRate[Index].unit = '%'
        KPISLineOverloadRate[Index].caseName = cases[Index].name
        KPISLineOverloadRate[Index].phase = {}
        // For the three phase --------------------------------------------------------
        let vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISLineOverloadRate[Index].phase.threePhase = {}
        KPISLineOverloadRate[Index].phase.threePhase.value = dt.Results
          .GetLineOverLoad(cases[Index].idx, Frame1, Frame2, LineIndex, op, vioArray)[0]
        KPISLineOverloadRate[Index].phase.threePhase.vioInstants = vioArray
        KPISLineOverloadRate[Index].phase.threePhase.vioCount = dt.Results
          .GetLineOverLoad(cases[Index].idx, Frame1, Frame2, LineIndex, op, vioArray)[1]
        // --For phase A ---------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISLineOverloadRate[Index].phase.A = {}
        KPISLineOverloadRate[Index].phase.A.value = dt.Results
          .GetLinePhaseOverLoad(cases[Index].idx, Frame1, Frame2, LineIndex, 0, vioArray)[0]
        KPISLineOverloadRate[Index].phase.A.vioInstants = vioArray
        KPISLineOverloadRate[Index].phase.A.vioCount = dt.Results
          .GetLinePhaseOverLoad(cases[Index].idx, Frame1, Frame2, LineIndex, 0, vioArray)[1]
        // --For phase B---------------------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISLineOverloadRate[Index].phase.B = {}
        KPISLineOverloadRate[Index].phase.B.value = dt.Results
          .GetLinePhaseOverLoad(cases[Index].idx, Frame1, Frame2, LineIndex, 1, vioArray)[0]
        KPISLineOverloadRate[Index].phase.B.vioInstants = vioArray
        KPISLineOverloadRate[Index].phase.B.vioCount = dt.Results
          .GetLinePhaseOverLoad(cases[Index].idx, Frame1, Frame2, LineIndex, 1, vioArray)[1]
        // ---For phase C-----------------------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISLineOverloadRate[Index].phase.C = {}
        KPISLineOverloadRate[Index].phase.C.value = dt.Results
          .GetLinePhaseOverLoad(cases[Index].idx, Frame1, Frame2, LineIndex, 2, vioArray)[0]
        KPISLineOverloadRate[Index].phase.C.vioInstants = vioArray
        KPISLineOverloadRate[Index].phase.C.vioCount = dt.Results
          .GetLinePhaseOverLoad(cases[Index].idx, Frame1, Frame2, LineIndex, 2, vioArray)[1]
        // console.log('KPISLineOverloadRate', KPISLineOverloadRate);
      }
      function KPIsLineIunbRate(Index) {
        KPISLineIunbRate[Index] = {}
        KPISLineIunbRate[Index].KPI = i18n.t('kpi.current_unbalance_rate')
        KPISLineIunbRate[Index].unit = '%'
        KPISLineIunbRate[Index].caseName = cases[Index].name
        KPISLineIunbRate[Index].phase = {}
        // For the three phase --------------------------------------------------------
        const vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISLineIunbRate[Index].phase.threePhase = {}
        KPISLineIunbRate[Index].phase.threePhase.value = dt.Results.GetRateLineIu(cases[Index].idx,
          Frame1, Frame2, LineIndex, vioArray)[0]
        KPISLineIunbRate[Index].phase.threePhase.vioInstants = vioArray
        KPISLineIunbRate[Index].phase.threePhase.vioCount = dt.Results
          .GetRateLineIu(cases[Index].idx, Frame1, Frame2, LineIndex, vioArray)[1]
        // console.log('KPISLineIunbRate', KPISLineIunbRate);
      }
      function KPIsLineAvgIunb(Index) {
        KPISLineAvgIunb[Index] = {}
        KPISLineAvgIunb[Index].KPI = i18n.t('kpi.current_unbalance_avg')
        KPISLineAvgIunb[Index].unit = '%'
        KPISLineAvgIunb[Index].caseName = cases[Index].name
        KPISLineAvgIunb[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISLineAvgIunb[Index].phase.threePhase = {}
        KPISLineAvgIunb[Index].phase.threePhase.value = dt.Results.GetMeanLineIu(cases[Index].idx,
          Frame1, Frame2, LineIndex)
        KPISLineAvgIunb[Index].phase.threePhase.vioInstants = null
        KPISLineAvgIunb[Index].phase.threePhase.vioCount = null
        // console.log('KPISLineAvgIunb', KPISLineAvgIunb);
      }
      function KPIsLineEnergyLosses(Index) {
        KPISLineEnergyLosses[Index] = {}
        KPISLineEnergyLosses[Index].KPI = i18n.t('kpi.energy_losses')
        KPISLineEnergyLosses[Index].unit = 'Wh'
        KPISLineEnergyLosses[Index].caseName = cases[Index].name
        KPISLineEnergyLosses[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISLineEnergyLosses[Index].phase.threePhase = {}
        KPISLineEnergyLosses[Index].phase.threePhase.value = dt.Results
          .GetLineEnergyLos(cases[Index].idx, Frame1, Frame2, LineIndex) * 1000
        KPISLineEnergyLosses[Index].phase.threePhase.vioCount = null
        KPISLineEnergyLosses[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISLineEnergyLosses[Index].phase.A = {}
        KPISLineEnergyLosses[Index].phase.A.value = dt.Results
          .GetLinePhaseEnergyLos(cases[Index].idx, Frame1, Frame2, LineIndex, 0) * 1000
        KPISLineEnergyLosses[Index].phase.A.vioCount = null
        KPISLineEnergyLosses[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISLineEnergyLosses[Index].phase.B = {}
        KPISLineEnergyLosses[Index].phase.B.value = dt.Results
          .GetLinePhaseEnergyLos(cases[Index].idx, Frame1, Frame2, LineIndex, 1) * 1000
        KPISLineEnergyLosses[Index].phase.B.vioCount = null
        KPISLineEnergyLosses[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISLineEnergyLosses[Index].phase.C = {}
        KPISLineEnergyLosses[Index].phase.C.value = dt.Results
          .GetLinePhaseEnergyLos(cases[Index].idx, Frame1, Frame2, LineIndex, 2) * 1000
        KPISLineEnergyLosses[Index].phase.C.vioCount = null
        KPISLineEnergyLosses[Index].phase.C.vioInstants = null
        // console.log('KPISLineEnergyLosses', KPISLineEnergyLosses);
      }
      function KPIsLineAvgS(Index, option1) {
        const op2 = 2 // for avg between 3 phases
        const op = option1 + op2
        KPISLineAvgS[Index] = {}
        KPISLineAvgS[Index].KPI = i18n.t('kpi.apparent_power_avg')
        KPISLineAvgS[Index].unit = 'kVA'
        KPISLineAvgS[Index].caseName = cases[Index].name
        KPISLineAvgS[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISLineAvgS[Index].phase.threePhase = {}
        KPISLineAvgS[Index].phase.threePhase.value = dt.Results.GetLineSavg(cases[Index].idx,
          Frame1, Frame2, LineIndex, op)
        KPISLineAvgS[Index].phase.threePhase.vioCount = null
        KPISLineAvgS[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISLineAvgS[Index].phase.A = {}
        KPISLineAvgS[Index].phase.A.value = dt.Results.GetLinePhaseSavg(cases[Index].idx,
          Frame1, Frame2, LineIndex, 0, op)
        KPISLineAvgS[Index].phase.A.vioCount = null
        KPISLineAvgS[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISLineAvgS[Index].phase.B = {}
        KPISLineAvgS[Index].phase.B.value = dt.Results.GetLinePhaseSavg(cases[Index].idx,
          Frame1, Frame2, LineIndex, 1, op)
        KPISLineAvgS[Index].phase.B.vioCount = null
        KPISLineAvgS[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISLineAvgS[Index].phase.C = {}
        KPISLineAvgS[Index].phase.C.value = dt.Results.GetLinePhaseSavg(cases[Index].idx,
          Frame1, Frame2, LineIndex, 2, op)
        KPISLineAvgS[Index].phase.C.vioCount = null
        KPISLineAvgS[Index].phase.C.vioInstants = null
        // ---For the neutral ------------------------------------------------------------------
        KPISLineAvgS[Index].phase.N = {}
        KPISLineAvgS[Index].phase.N.value = dt.Results.GetLinePhaseSavg(cases[Index].idx,
          Frame1, Frame2, LineIndex, 3, op)
        KPISLineAvgS[Index].phase.N.vioCount = null
        KPISLineAvgS[Index].phase.N.vioInstants = null
        // console.log('KPISLineAvgS', KPISLineAvgS);
      }
      function KPIsLineAvgSunb(Index, option1) {
        const op2 = 2 // for avg between 3 phases
        const op = option1 + op2
        KPISLineAvgSunb[Index] = {}
        KPISLineAvgSunb[Index].KPI = i18n.t('kpi.apparent_power_phase_share')
        KPISLineAvgSunb[Index].unit = '%'
        KPISLineAvgSunb[Index].caseName = cases[Index].name
        KPISLineAvgSunb[Index].phase = {}
        // --For phase A ---------------------------------------------------------
        KPISLineAvgSunb[Index].phase.A = {}
        KPISLineAvgSunb[Index].phase.A.value = dt.Results.GetLineMeanPhaseUnbal(cases[Index].idx,
          Frame1, Frame2, LineIndex, 0, op)[1]
        KPISLineAvgSunb[Index].phase.A.vioCount = null
        KPISLineAvgSunb[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISLineAvgSunb[Index].phase.B = {}
        KPISLineAvgSunb[Index].phase.B.value = dt.Results.GetLineMeanPhaseUnbal(cases[Index].idx,
          Frame1, Frame2, LineIndex, 1, op)[1]
        KPISLineAvgSunb[Index].phase.B.vioCount = null
        KPISLineAvgSunb[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISLineAvgSunb[Index].phase.C = {}
        KPISLineAvgSunb[Index].phase.C.value = dt.Results.GetLineMeanPhaseUnbal(cases[Index].idx,
          Frame1, Frame2, LineIndex, 2, op)[1]
        KPISLineAvgSunb[Index].phase.C.vioCount = null
        KPISLineAvgSunb[Index].phase.C.vioInstants = null
        // console.log('KPISLineAvgSunb', KPISLineAvgSunb);
      }

      function orderDataForTable() {
        let temp3P = {}; let tempA = {}; let tempB = {}; let tempC = {}; let tempN = {}
        let flag3P = false; let flagA = false; let flagB = false
        let flagC = false; let flagN = false;

        let computes = [
          { "objectName": "KPISLineIMin", "objectElem": KPISLineIMin, "phaseOne": '3P', "phaseTwo": 'A', "phaseThree": 'B', "phaseFour": 'C', "phaseFive": 'N', "base": 100 },
          { "objectName": "KPISLineIMax", "objectElem": KPISLineIMax, "phaseOne": '3P', "phaseTwo": 'A', "phaseThree": 'B', "phaseFour": 'C', "phaseFive": 'N', "base": 100 },
          { "objectName": "KPISLineIavg", "objectElem": KPISLineIavg, "phaseOne": '3P', "phaseTwo": 'A', "phaseThree": 'B', "phaseFour": 'C', "phaseFive": 'N', "base": 100 },
          { "objectName": "KPISLineCapFac", "objectElem": KPISLineCapFac, "phaseOne": '3P', "phaseTwo": 'A', "phaseThree": 'B', "phaseFour": 'C', "phaseFive": 'N', "base": 10 },
          { "objectName": "KPISLineOverUtiRate", "objectElem": KPISLineOverUtiRate, "phaseOne": '3P', "phaseTwo": 'A', "phaseThree": 'B', "phaseFour": 'C', "phaseFive": 'N', "base": 100 },
          { "objectName": "KPISLineOverloadRate", "objectElem": KPISLineOverloadRate, "phaseOne": '3P', "phaseTwo": 'A', "phaseThree": 'B', "phaseFour": 'C', "phaseFive": 'N', "base": 100 },
          { "objectName": "KPISLineIunbRate", "objectElem": KPISLineIunbRate, "phaseOne": '3P', "phaseTwo": 'A', "phaseThree": 'B', "phaseFour": 'C', "phaseFive": 'N', "base": 100 },
          { "objectName": "KPISLineAvgIunb", "objectElem": KPISLineAvgIunb, "phaseOne": '3P', "phaseTwo": 'A', "phaseThree": 'B', "phaseFour": 'C', "phaseFive": 'N', "base": 100 },
          { "objectName": "KPISLineEnergyLosses", "objectElem": KPISLineEnergyLosses, "phaseOne": '3P', "phaseTwo": 'A', "phaseThree": 'B', "phaseFour": 'C', "phaseFive": 'N', "base": 100 },
          { "objectName": "KPISLineAvgS", "objectElem": KPISLineAvgS, "phaseOne": '3P', "phaseTwo": 'A', "phaseThree": 'B', "phaseFour": 'C', "phaseFive": 'N', "base": 100 },
          { "objectName": "KPISLineAvgSunb", "objectElem": KPISLineAvgSunb, "phaseOne": '3P', "phaseTwo": 'A', "phaseThree": 'B', "phaseFour": 'C', "phaseFive": 'N', "base": 10 },
        ];

        for (const compute of computes) {

          temp3P = {}; tempA = {}; tempB = {}; tempC = {}; tempN = {};
          flag3P = false; flagA = false; flagB = false; flagC = false; flagN = false;

          compute.objectElem.forEach((element, index) => {

            if (index === 0) {
              if (element.phase.threePhase) {
                temp3P.KPI = element.KPI;
                temp3P.Unit = element.unit;
                temp3P.Phase = compute.phaseOne;
                flag3P = true;
                temp3P.base = element.phase.threePhase.value;
                temp3P.base = Math.round(temp3P.base * compute.base) / compute.base;
                if (cases.length === 1) {
                  dataForKPItable.push(temp3P);
                }

              }
              if (element.phase.A) {
                tempA.KPI = element.KPI;
                tempA.Unit = element.unit;
                tempA.Phase = compute.phaseTwo;
                flagA = true;
                tempA.base = element.phase.A.value;
                tempA.base = Math.round(tempA.base * compute.base) / compute.base;
                if (cases.length === 1) {
                  dataForKPItable.push(tempA);
                }
              }
              if (element.phase.B) {
                tempB.KPI = element.KPI;
                tempB.Unit = element.unit;
                tempB.Phase = compute.phaseThree;
                flagB = true;
                tempB.base = element.phase.B.value;
                tempB.base = Math.round(tempB.base * compute.base) / compute.base;
                if (cases.length === 1) {
                  dataForKPItable.push(tempB);
                }
              }
              if (element.phase.C) {
                tempC.KPI = element.KPI;
                tempC.Unit = element.unit;
                tempC.Phase = compute.phaseFour;
                flagC = true;
                tempC.base = element.phase.C.value;
                tempC.base = Math.round(tempC.base * compute.base) / compute.base;
                if (cases.length === 1) {
                  dataForKPItable.push(tempC);
                }
              }
              if (element.phase.N) {
                tempN.KPI = element.KPI;
                tempN.Unit = element.unit;
                tempN.Phase = compute.phaseFive;
                flagN = true;
                tempN.base = element.phase.N.value;
                tempN.base = Math.round(tempN.base * compute.base) / compute.base;
                if (cases.length === 1) {
                  dataForKPItable.push(tempN);
                }
              }
            } else if (index !== 0) {
              if (flag3P === true) {
                temp3P[element.caseName] = element.phase.threePhase.value;
                temp3P[element.caseName] = Math.round(temp3P[element.caseName] * compute.base) / compute.base;
                if (index === cases.length - 1) {
                  dataForKPItable.push(temp3P);
                }
              }
              if (flagA === true) {
                tempA[element.caseName] = element.phase.A.value;
                tempA[element.caseName] = Math.round(tempA[element.caseName] * compute.base) / compute.base;
                if (index === cases.length - 1) {
                  dataForKPItable.push(tempA);
                }
              }
              if (flagB === true) {
                tempB[element.caseName] = element.phase.B.value;
                tempB[element.caseName] = Math.round(tempB[element.caseName] * compute.base) / compute.base;
                if (index === cases.length - 1) {
                  dataForKPItable.push(tempB);
                }
              }
              if (flagC === true) {
                tempC[element.caseName] = element.phase.C.value;
                tempC[element.caseName] = Math.round(tempC[element.caseName] * compute.base) / compute.base;
                if (index === cases.length - 1) {
                  dataForKPItable.push(tempC);
                }
              }
              if (flagN === true) {
                tempN[element.caseName] = element.phase.N.value;
                tempN[element.caseName] = Math.round(tempN[element.caseName] * compute.base) / compute.base;
                if (index === cases.length - 1) {
                  dataForKPItable.push(tempN);
                }
              }
            }
          });

        }

      }
      for (let iter = 0; iter < cases.length; iter += 1) {
        // Initialize overall KPI function
        // eslint-disable-next-line no-await-in-loop
        // await dt.Results.KPI_init(cases[iter].idx, Frame1, Frame2)
        // this.tempdt = dt
        LineIndex = dt.Results.GetLineIndex(Number(idElement))

        // Each case could have different number. We need to adjust after get kpis, and before to return to the case
        dt.Results.AdjustBusCount(dt.cases[cases[iter].name].mod.NewBusCount) // Calculate well kpis, plots with ESS

        KPIsLineImin(iter, op1)
        KPIsLineImax(iter, op1)
        KPIsLineIavg(iter, op1)
        KPIsCapacityFactor(iter, op1)
        KPIsLineOverUtiRate(iter, op1)
        KPIsLineOverloadRate(iter, op1)
        KPIsLineIunbRate(iter)
        KPIsLineAvgIunb(iter)
        KPIsLineEnergyLosses(iter)
        KPIsLineAvgS(iter, op1)
        KPIsLineAvgSunb(iter, op1)
      }
      orderDataForTable()

      dt.Results.AdjustBusCount(dt.cases[caseSelected].mod.NewBusCount) // Calculate well kpis, plots with ESS

      return dataForKPItable
    }
  }
}
